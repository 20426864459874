@import url(https://fonts.googleapis.com/css?family=Mulish:300,600|Open+Sans:300,400,500,600&subset=latin-ext);
@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.sidebar {
  height: 100vh;
}
.sidebar .vs-sidebar-content {
    z-index: 2 !important;
}
.sidebar .clickicon {
    width: 260px;
}
.sidebar .clickiconreduced {
    width: 50px;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.search-box ion-icon {
  font-size: 1.6rem;
}
.search-box .sbx-custom {
  display: inline-block;
  position: relative;
  width: 30vw;
  height: 35px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}
.search-box .sbx-custom .sbx-custom__wrapper {
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
.search-box .sbx-custom .sbx-custom__input {
    display: inline-block;
    border: 0;
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px #303030;
    background: aliceblue;
    padding-right: 25px;
    padding-left: 9px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.navbar {
  height: 75px;
  margin-left: 200px;
  background-color: #2e517b;
}
.navbar.reduced {
    margin-left: 50px;
}
.navbar .navbar-elements {
    color: aliceblue;
    width: 100%;
}
.navbar .navbar-elements .user-profile .alerts {
      cursor: pointer;
      position: relative;
}
.navbar .navbar-elements .user-profile .alerts ion-icon {
        font-size: 2rem;
}
.navbar .navbar-elements .user-profile .alerts .alerts-count {
        border-radius: 0.4rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        color: black;
        text-align: center;
        text-anchor: center;
        z-index: 2;
}
.navbar .navbar-elements .user-profile .alerts .unread-alerts {
        position: absolute;
        left: 0rem;
        top: 0.2rem;
        background-color: #f49e4c;
}
.navbar .navbar-elements .user-profile .alerts .unhandled-alerts {
        position: absolute;
        right: 0rem;
        top: 0.2rem;
        background-color: #f5ee9e;
}
.navbar .navbar-elements .user-profile .profile {
      cursor: pointer;
}
.navbar .navbar-elements .user-profile .profile ion-icon {
        font-size: 3rem;
}
.navbar .navbar-elements .user-profile .logout {
      cursor: pointer;
}
.navbar .navbar-elements .user-profile .logout ion-icon {
        font-size: 2rem;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-modal {
  height: 0;
  width: 0;
}
.vuestic-modal .modal {
    z-index: 3;
    display: block;
}
.vuestic-modal .modal-dialog,
  .vuestic-modal .modal-backdrop {
    transition: all 0.5s ease;
    z-index: 2;
}
.vuestic-modal .modal-enter .modal-dialog,
  .vuestic-modal .modal-leave-active .modal-dialog {
    opacity: 0;
    transform: translateY(-30%);
}
.vuestic-modal .modal-enter .modal-backdrop,
  .vuestic-modal .modal-leave-active .modal-backdrop {
    opacity: 0;
}
.vuestic-modal .modal-backdrop {
    opacity: 0.5;
    min-width: 100%;
    min-height: 100%;
}
.vuestic-modal .modal-header {
    height: 55px;
    padding: 0 1.5625rem;
    border-bottom: 2px solid #f5f1f5;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.vuestic-modal .close-modal {
    margin-left: 1rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    cursor: pointer;
}
.vuestic-modal .modal-content {
    border-radius: 0;
}
.vuestic-modal .modal-footer {
    justify-content: center;
    padding: 0 25px;
    padding-bottom: calc( 25px - 20px);
    flex-wrap: wrap;
}
.vuestic-modal .modal-footer .btn {
      margin: 0 10px 20px 10px;
}
.vuestic-modal .modal-dialog {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-date-picker-container:not(:last-child) {
  margin-right: 1.08rem;
}
.vuestic-date-picker-container .btn-today {
  height: 25px;
  width: 25px;
  border: 1px solid #737373;
  border-top-left-radius: 4px;
}
input.vuestic-date-picker {
  font-size: 1rem;
}
.flatpickr-calendar {
  width: 15.5rem !important;
  background-color: #333333;
  border-radius: 0;
  box-shadow: none;
}
.flatpickr-calendar.arrowTop:before {
    content: none;
    border-bottom-color: #333333;
}
.flatpickr-calendar.arrowTop:after {
    content: none;
    border-bottom-color: #333333;
}
.flatpickr-calendar.arrowBottom:before {
    content: none;
    border-top-color: #333333;
}
.flatpickr-calendar.arrowBottom:after {
    content: none;
    border-top-color: #333333;
}
.flatpickr-calendar .flatpickr-days {
    width: 15.5rem !important;
    padding: 0 0.5rem 0.5rem;
    display: block;
}
.flatpickr-calendar .flatpickr-days .dayContainer {
      width: 14.875rem;
      max-width: 14.875rem;
      min-width: 14.875rem;
      margin: -0.1875rem;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day {
        height: 1.75rem;
        line-height: 1.7;
        font-size: 1rem;
        flex: 0 0 1.75rem;
        margin: 0.1875rem;
        border: none;
        color: #fff;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.today {
          background-color: #404040;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day:hover {
          background-color: #4d4d4d;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected {
          background-color: #66ccff;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.nextMonthDay, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.prevMonthDay {
          color: #999999;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled {
          color: #999999;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.disabled:hover {
            background-color: inherit;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
          border-radius: 2rem;
          box-shadow: none;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.endRange {
          background-color: #66ccff;
}
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
          background-color: #161616;
}
.flatpickr-calendar .flatpickr-months {
    height: 2.625rem;
    border-bottom: solid 0.125rem #161616;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month {
      height: 100%;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month {
        padding-top: 0.625rem;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
          cursor: pointer;
          color: #fff;
          outline: none;
          overflow: hidden;
          text-indent: 0.01px;
          text-overflow: "";
          background: url("https://www.freeiconspng.com/uploads/white-down-arrow-png-2.png") no-repeat right 2px bottom 5px;
          background-size: auto;
          background-size: 13px;
          background-color: #333333;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          /* width: 100%; */
          font-size: 0.9rem;
          height: 1.71rem;
          padding: 0.1125rem 0.1125rem 0.05625rem;
          padding-right: 0.1125rem;
          padding-right: 18px;
          border: none;
          line-height: 1.6;
          box-shadow: none;
          text-align: right;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
            background-color: #4d4d4d;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month {
          font-size: 1rem;
          font-weight: inherit;
          color: #fff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-year {
          font-size: 1rem;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .numInput.cur-year {
          color: #fff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp:hover::after {
          border-bottom-color: #66ccff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowUp::after {
          border-bottom-color: #fff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown:hover::after {
          border-top-color: #66ccff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .arrowDown::after {
          border-top-color: #fff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
    .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.625rem;
      width: 1.625rem;
      padding: 0;
      color: #fff;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover,
      .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
        color: #66ccff;
}
.flatpickr-calendar .flatpickr-weekdays {
    padding: 0 0.5rem;
}
.flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer {
      justify-content: space-between;
}
.flatpickr-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
        letter-spacing: 0.0625rem;
        flex: 0 0 1.75rem;
        color: gray;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.6875rem;
}
.flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weekday {
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    color: gray;
    font-weight: bold;
    font-size: 0.6875rem;
}
.flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 2px 0 0 #161616;
    padding-bottom: 0.5rem;
    margin-top: -0.375rem;
}
.flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks .flatpickr-day {
      height: 1.75rem;
      line-height: 1.7;
      font-size: 1rem;
      flex: 0 0 1.75rem;
      margin-top: 0.375rem;
      color: #999999;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    border-top: solid 0.125rem #161616;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time :hover {
      background-color: #4d4d4d;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper:hover {
      background-color: #4d4d4d;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .numInput {
      color: #fff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowUp::after {
      border-bottom-color: #fff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowUp:hover::after {
      border-bottom-color: #66ccff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowDown::after {
      border-top-color: #fff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .numInputWrapper .arrowDown:hover::after {
      border-top-color: #66ccff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-time-separator {
      color: #fff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-am-pm {
      color: #fff;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time .flatpickr-am-pm:hover {
        background-color: #4d4d4d;
}
.flatpickr-calendar .shortcut-buttons-flatpickr-button {
    color: white;
    border: none;
    background-color: #333333;
    border-radius: 5px;
    margin-bottom: 5px;
}
.flatpickr-calendar .shortcut-buttons-flatpickr-button:hover {
      background-color: #4d4d4d;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.contact-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.detention-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.domiciliation-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.room-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.contact-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.housingHelp-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.formation-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.qualification-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.experience-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.application-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.resource-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-list-item {
  min-height: 2.7rem;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 8px 14.72px 1.28px rgba(101, 169, 119, 0.3);
  margin: 0 1rem;
  padding: 0.1rem 0.1rem;
}
.file-upload-list-item + .file-upload-list-item {
    margin-top: 0.5rem;
}
.file-upload-list-item-name {
    margin: auto;
}
.file-upload-list-item-size {
    margin: auto;
    color: #919191;
}
.file-upload-list-item-date {
    margin: auto;
    color: #919191;
}
.file-upload-list-item-buttons {
    display: flex;
    justify-content: flex-end;
}
.file-upload-list-item--undo {
    background: none;
    box-shadow: none;
}
@media (max-width: 1200px) {
.file-upload-list-item-buttons {
    margin-top: 0.7rem;
    justify-content: flex-start;
}
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-gallery-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 1rem;
}
.file-upload-gallery-item:hover .file-upload-gallery-item-overlay {
    display: flex;
}
.file-upload-gallery-item-overlay {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    flex-direction: column;
    padding: 0.5rem;
    background: rgba(51, 204, 0, 0.8);
    z-index: 1;
}
.file-upload-gallery-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: scale-down;
       object-fit: scale-down;
}
.file-upload-gallery-item-name {
    color: #34495e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.file-upload-gallery-item-size {
    color: #34495e;
}
.file-upload-gallery-item-button {
    margin-top: auto;
    text-align: left;
}
.file-upload-gallery-item-undo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.7rem 0 0;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.file-upload-single-item {
  display: flex;
}
.file-upload-single-item-name {
    margin-right: 1rem;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload-list {
  padding-bottom: 2rem;
  margin: 0 -1rem;
}
.vuestic-file-upload-gallery {
  padding: 0 0 1rem;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload-container {
  position: relative;
}
.vuestic-file-upload-field {
  padding: 0 0 1.3rem;
  overflow: hidden;
  display: inline-block;
  position: relative;
}
.vuestic-file-upload-field--dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    padding: 0 2rem;
    transition: height 0.2s;
    overflow: visible;
    position: static;
}
.vuestic-file-upload-field-text {
    padding-right: 10px;
}
.vuestic-file-upload-field-input {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    font-size: 999px;
    min-height: 100%;
    min-width: 100%;
    color: transparent;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
}
@media (max-width: 576px) {
.vuestic-file-upload-field--dropzone {
    flex-direction: column;
    padding: 0;
}
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.vuestic-file-upload--dropzone {
  background-color: #e9fde8;
  padding: 0 2rem;
  overflow: hidden;
}
.btn-text {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.btn-text + .btn-text {
    margin-left: 1.5rem;
}
.btn-text--primary {
    color: #33cc00;
}
.btn-text--primary:hover {
      opacity: 0.6;
}
.btn-text--secondary {
    color: #fff;
}
@media (max-width: 576px) {
.vuestic-file-upload--dropzone {
    padding: 0 1rem;
}
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.mail-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.follow-modal .md-list-item {
  z-index: 10002;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.layout {
  min-width: 100%;
}
.layout .panel-conatiner {
    background-color: #f5f1f5;
}
.layout .panel-conatiner .main-panel {
      margin-left: 260px;
}
.layout .panel-conatiner .main-panel.reduced {
        margin-left: 50px !important;
}
.vs-sidebar-content .vs-sidebar__logo img {
  max-width: 200px !important;
  max-height: 100px !important;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #66ccff;
  --secondary: #fff;
  --success: #33cc00;
  --info: #4ab2e3;
  --warning: #f6a336;
  --danger: #cc1417;
  --light: #eff4f5;
  --dark: #161616;
  --pale: #c8c8c8;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  color: #34495e;
  text-align: left;
  background-color: #f5f1f5;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #66ccff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
    color: #99ddff;
    text-decoration: none;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1;
}
h1, .h1 {
  font-size: 2.25rem;
}
h2, .h2 {
  font-size: 1.8rem;
}
h3, .h3 {
  font-size: 1.575rem;
}
h4, .h4 {
  font-size: 1.35rem;
}
h5, .h5 {
  font-size: 1.125rem;
}
h6, .h6 {
  font-size: 0.9rem;
}
.lead {
  font-size: 1.125rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #919191;
}
.blockquote-footer::before {
    content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f1f5;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
    color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
.container, .container-sm {
    max-width: 540px;
}
}
@media (min-width: 768px) {
.container, .container-sm, .container-md {
    max-width: 720px;
}
}
@media (min-width: 992px) {
.container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
}
}
@media (min-width: 1200px) {
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
}
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
.col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}
.row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}
.row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}
.row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
}
.row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-sm-first {
    order: -1;
}
.order-sm-last {
    order: 13;
}
.order-sm-0 {
    order: 0;
}
.order-sm-1 {
    order: 1;
}
.order-sm-2 {
    order: 2;
}
.order-sm-3 {
    order: 3;
}
.order-sm-4 {
    order: 4;
}
.order-sm-5 {
    order: 5;
}
.order-sm-6 {
    order: 6;
}
.order-sm-7 {
    order: 7;
}
.order-sm-8 {
    order: 8;
}
.order-sm-9 {
    order: 9;
}
.order-sm-10 {
    order: 10;
}
.order-sm-11 {
    order: 11;
}
.order-sm-12 {
    order: 12;
}
.offset-sm-0 {
    margin-left: 0;
}
.offset-sm-1 {
    margin-left: 8.33333%;
}
.offset-sm-2 {
    margin-left: 16.66667%;
}
.offset-sm-3 {
    margin-left: 25%;
}
.offset-sm-4 {
    margin-left: 33.33333%;
}
.offset-sm-5 {
    margin-left: 41.66667%;
}
.offset-sm-6 {
    margin-left: 50%;
}
.offset-sm-7 {
    margin-left: 58.33333%;
}
.offset-sm-8 {
    margin-left: 66.66667%;
}
.offset-sm-9 {
    margin-left: 75%;
}
.offset-sm-10 {
    margin-left: 83.33333%;
}
.offset-sm-11 {
    margin-left: 91.66667%;
}
}
@media (min-width: 768px) {
.col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}
.row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}
.row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}
.row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
}
.row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-md-first {
    order: -1;
}
.order-md-last {
    order: 13;
}
.order-md-0 {
    order: 0;
}
.order-md-1 {
    order: 1;
}
.order-md-2 {
    order: 2;
}
.order-md-3 {
    order: 3;
}
.order-md-4 {
    order: 4;
}
.order-md-5 {
    order: 5;
}
.order-md-6 {
    order: 6;
}
.order-md-7 {
    order: 7;
}
.order-md-8 {
    order: 8;
}
.order-md-9 {
    order: 9;
}
.order-md-10 {
    order: 10;
}
.order-md-11 {
    order: 11;
}
.order-md-12 {
    order: 12;
}
.offset-md-0 {
    margin-left: 0;
}
.offset-md-1 {
    margin-left: 8.33333%;
}
.offset-md-2 {
    margin-left: 16.66667%;
}
.offset-md-3 {
    margin-left: 25%;
}
.offset-md-4 {
    margin-left: 33.33333%;
}
.offset-md-5 {
    margin-left: 41.66667%;
}
.offset-md-6 {
    margin-left: 50%;
}
.offset-md-7 {
    margin-left: 58.33333%;
}
.offset-md-8 {
    margin-left: 66.66667%;
}
.offset-md-9 {
    margin-left: 75%;
}
.offset-md-10 {
    margin-left: 83.33333%;
}
.offset-md-11 {
    margin-left: 91.66667%;
}
}
@media (min-width: 992px) {
.col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}
.row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}
.row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}
.row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
}
.row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-lg-first {
    order: -1;
}
.order-lg-last {
    order: 13;
}
.order-lg-0 {
    order: 0;
}
.order-lg-1 {
    order: 1;
}
.order-lg-2 {
    order: 2;
}
.order-lg-3 {
    order: 3;
}
.order-lg-4 {
    order: 4;
}
.order-lg-5 {
    order: 5;
}
.order-lg-6 {
    order: 6;
}
.order-lg-7 {
    order: 7;
}
.order-lg-8 {
    order: 8;
}
.order-lg-9 {
    order: 9;
}
.order-lg-10 {
    order: 10;
}
.order-lg-11 {
    order: 11;
}
.order-lg-12 {
    order: 12;
}
.offset-lg-0 {
    margin-left: 0;
}
.offset-lg-1 {
    margin-left: 8.33333%;
}
.offset-lg-2 {
    margin-left: 16.66667%;
}
.offset-lg-3 {
    margin-left: 25%;
}
.offset-lg-4 {
    margin-left: 33.33333%;
}
.offset-lg-5 {
    margin-left: 41.66667%;
}
.offset-lg-6 {
    margin-left: 50%;
}
.offset-lg-7 {
    margin-left: 58.33333%;
}
.offset-lg-8 {
    margin-left: 66.66667%;
}
.offset-lg-9 {
    margin-left: 75%;
}
.offset-lg-10 {
    margin-left: 83.33333%;
}
.offset-lg-11 {
    margin-left: 91.66667%;
}
}
@media (min-width: 1200px) {
.col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}
.row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}
.row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}
.row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
}
.row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.order-xl-first {
    order: -1;
}
.order-xl-last {
    order: 13;
}
.order-xl-0 {
    order: 0;
}
.order-xl-1 {
    order: 1;
}
.order-xl-2 {
    order: 2;
}
.order-xl-3 {
    order: 3;
}
.order-xl-4 {
    order: 4;
}
.order-xl-5 {
    order: 5;
}
.order-xl-6 {
    order: 6;
}
.order-xl-7 {
    order: 7;
}
.order-xl-8 {
    order: 8;
}
.order-xl-9 {
    order: 9;
}
.order-xl-10 {
    order: 10;
}
.order-xl-11 {
    order: 11;
}
.order-xl-12 {
    order: 12;
}
.offset-xl-0 {
    margin-left: 0;
}
.offset-xl-1 {
    margin-left: 8.33333%;
}
.offset-xl-2 {
    margin-left: 16.66667%;
}
.offset-xl-3 {
    margin-left: 25%;
}
.offset-xl-4 {
    margin-left: 33.33333%;
}
.offset-xl-5 {
    margin-left: 41.66667%;
}
.offset-xl-6 {
    margin-left: 50%;
}
.offset-xl-7 {
    margin-left: 58.33333%;
}
.offset-xl-8 {
    margin-left: 66.66667%;
}
.offset-xl-9 {
    margin-left: 75%;
}
.offset-xl-10 {
    margin-left: 83.33333%;
}
.offset-xl-11 {
    margin-left: 91.66667%;
}
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #34495e;
}
.table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 0 solid #eceeef;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 0 solid #eceeef;
}
.table tbody + tbody {
    border-top: 0 solid #eceeef;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 0 solid #eceeef;
}
.table-bordered th,
  .table-bordered td {
    border: 0 solid #eceeef;
}
.table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 0;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #34495e;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d4f1ff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #afe4ff;
}
.table-hover .table-primary:hover {
  background-color: #bbe9ff;
}
.table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bbe9ff;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white;
}
.table-hover .table-secondary:hover {
  background-color: #f2f2f2;
}
.table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c6f1b8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #95e47a;
}
.table-hover .table-success:hover {
  background-color: #b5eda3;
}
.table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b5eda3;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #cce9f7;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a1d7f0;
}
.table-hover .table-info:hover {
  background-color: #b6dff4;
}
.table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b6dff4;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce5c7;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #facf96;
}
.table-hover .table-warning:hover {
  background-color: #fbdaaf;
}
.table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbdaaf;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1bdbe;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e48586;
}
.table-hover .table-danger:hover {
  background-color: #eda8a9;
}
.table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eda8a9;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfcfc;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f7f9fa;
}
.table-hover .table-light:hover {
  background-color: #ecf1f1;
}
.table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ecf1f1;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bebebe;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #868686;
}
.table-hover .table-dark:hover {
  background-color: #b1b1b1;
}
.table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b1b1b1;
}
.table-pale,
.table-pale > th,
.table-pale > td {
  background-color: #f0f0f0;
}
.table-pale th,
.table-pale td,
.table-pale thead th,
.table-pale tbody + tbody {
  border-color: #e2e2e2;
}
.table-hover .table-pale:hover {
  background-color: #e3e3e3;
}
.table-hover .table-pale:hover > td,
  .table-hover .table-pale:hover > th {
    background-color: #e3e3e3;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #eceeef;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55;
}
.table-dark.table-bordered {
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
.table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-sm > .table-bordered {
      border: 0;
}
}
@media (max-width: 767.98px) {
.table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-md > .table-bordered {
      border: 0;
}
}
@media (max-width: 991.98px) {
.table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-lg > .table-bordered {
      border: 0;
}
}
@media (max-width: 1199.98px) {
.table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive-xl > .table-bordered {
      border: 0;
}
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
    border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #c6bec2;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.form-control {
      transition: none;
}
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e6f7ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 0px);
  padding-bottom: calc(0.375rem + 0px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 0px);
  padding-bottom: calc(0.5rem + 0px);
  font-size: 1.125rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 0px);
  padding-bottom: calc(0.25rem + 0px);
  font-size: 0.7875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #34495e;
  background-color: transparent;
  border: solid transparent;
  border-width: 0px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 0px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 0px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size], select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #33cc00;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(51, 204, 0, 0.9);
  border-radius: 0.45rem;
}
.form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #33cc00;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2333cc00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #33cc00;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #33cc00;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2333cc00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #33cc00;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #33cc00;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #33cc00;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #33cc00;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #40ff00;
  background-color: #40ff00;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #33cc00;
}
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #33cc00;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #33cc00;
  box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #cc1417;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: .1rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(204, 20, 23, 0.9);
  border-radius: 0.45rem;
}
.form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #cc1417;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cc1417' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc1417' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #cc1417;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #cc1417;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cc1417' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cc1417' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #cc1417;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #cc1417;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #cc1417;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #cc1417;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ea292c;
  background-color: #ea292c;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #cc1417;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #cc1417;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #cc1417;
  box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
    width: 100%;
}
@media (min-width: 576px) {
.form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
}
.form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
}
.form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
}
.form-inline .form-control-plaintext {
      display: inline-block;
}
.form-inline .input-group,
    .form-inline .custom-select {
      width: auto;
}
.form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
}
.form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
}
.form-inline .custom-control {
      align-items: center;
      justify-content: center;
}
.form-inline .custom-control-label {
      margin-bottom: 0;
}
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #34495e;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 1.1rem 3.9rem;
  font-size: 0.9rem;
  line-height: 1.25;
  border-radius: 1.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.btn {
      transition: none;
}
}
.btn:hover {
    color: #34495e;
    text-decoration: none;
}
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.btn.disabled, .btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #212529;
  background-color: #66ccff;
  border-color: #66ccff;
}
.btn-primary:hover {
    color: #212529;
    background-color: #40bfff;
    border-color: #33bbff;
}
.btn-primary:focus, .btn-primary.focus {
    color: #212529;
    background-color: #40bfff;
    border-color: #33bbff;
    box-shadow: 0 0 0 0.2rem rgba(92, 179, 223, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #66ccff;
    border-color: #66ccff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #33bbff;
    border-color: #26b7ff;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 179, 223, 0.5);
}
.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
}
.btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #33cc00;
  border-color: #33cc00;
}
.btn-success:hover {
    color: #fff;
    background-color: #29a600;
    border-color: #269900;
}
.btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #29a600;
    border-color: #269900;
    box-shadow: 0 0 0 0.2rem rgba(82, 212, 38, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #33cc00;
    border-color: #33cc00;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #269900;
    border-color: #238c00;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 212, 38, 0.5);
}
.btn-info {
  color: #212529;
  background-color: #4ab2e3;
  border-color: #4ab2e3;
}
.btn-info:hover {
    color: #fff;
    background-color: #29a4de;
    border-color: #219ed9;
}
.btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #29a4de;
    border-color: #219ed9;
    box-shadow: 0 0 0 0.2rem rgba(68, 157, 199, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #4ab2e3;
    border-color: #4ab2e3;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #219ed9;
    border-color: #2096cd;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 157, 199, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #f6a336;
  border-color: #f6a336;
}
.btn-warning:hover {
    color: #212529;
    background-color: #f49211;
    border-color: #ee8c0b;
}
.btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #f49211;
    border-color: #ee8c0b;
    box-shadow: 0 0 0 0.2rem rgba(214, 144, 52, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f6a336;
    border-color: #f6a336;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ee8c0b;
    border-color: #e2850a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 144, 52, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #cc1417;
  border-color: #cc1417;
}
.btn-danger:hover {
    color: #fff;
    background-color: #a91113;
    border-color: #9e0f12;
}
.btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #a91113;
    border-color: #9e0f12;
    box-shadow: 0 0 0 0.2rem rgba(212, 55, 58, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #cc1417;
    border-color: #cc1417;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #9e0f12;
    border-color: #920e10;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 55, 58, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #eff4f5;
  border-color: #eff4f5;
}
.btn-light:hover {
    color: #212529;
    background-color: #d7e4e6;
    border-color: #d0dee1;
}
.btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d7e4e6;
    border-color: #d0dee1;
    box-shadow: 0 0 0 0.2rem rgba(208, 213, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #eff4f5;
    border-color: #eff4f5;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d0dee1;
    border-color: #c8d9dc;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 213, 214, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #161616;
  border-color: #161616;
}
.btn-dark:hover {
    color: #fff;
    background-color: #030303;
    border-color: black;
}
.btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #030303;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(57, 57, 57, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #161616;
    border-color: #161616;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 57, 57, 0.5);
}
.btn-pale {
  color: #212529;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-pale:hover {
    color: #212529;
    background-color: #b5b5b5;
    border-color: #afafaf;
}
.btn-pale:focus, .btn-pale.focus {
    color: #212529;
    background-color: #b5b5b5;
    border-color: #afafaf;
    box-shadow: 0 0 0 0.2rem rgba(175, 176, 176, 0.5);
}
.btn-pale.disabled, .btn-pale:disabled {
    color: #212529;
    background-color: #c8c8c8;
    border-color: #c8c8c8;
}
.btn-pale:not(:disabled):not(.disabled):active, .btn-pale:not(:disabled):not(.disabled).active,
  .show > .btn-pale.dropdown-toggle {
    color: #212529;
    background-color: #afafaf;
    border-color: #a8a8a8;
}
.btn-pale:not(:disabled):not(.disabled):active:focus, .btn-pale:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pale.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(175, 176, 176, 0.5);
}
.btn-outline-primary {
  color: #66ccff;
  border-color: #66ccff;
}
.btn-outline-primary:hover {
    color: #212529;
    background-color: #66ccff;
    border-color: #66ccff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #66ccff;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #66ccff;
    border-color: #66ccff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.5);
}
.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
}
.btn-outline-secondary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fff;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-success {
  color: #33cc00;
  border-color: #33cc00;
}
.btn-outline-success:hover {
    color: #fff;
    background-color: #33cc00;
    border-color: #33cc00;
}
.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #33cc00;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #33cc00;
    border-color: #33cc00;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.5);
}
.btn-outline-info {
  color: #4ab2e3;
  border-color: #4ab2e3;
}
.btn-outline-info:hover {
    color: #212529;
    background-color: #4ab2e3;
    border-color: #4ab2e3;
}
.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(74, 178, 227, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #4ab2e3;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #4ab2e3;
    border-color: #4ab2e3;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(74, 178, 227, 0.5);
}
.btn-outline-warning {
  color: #f6a336;
  border-color: #f6a336;
}
.btn-outline-warning:hover {
    color: #212529;
    background-color: #f6a336;
    border-color: #f6a336;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 163, 54, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f6a336;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f6a336;
    border-color: #f6a336;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 163, 54, 0.5);
}
.btn-outline-danger {
  color: #cc1417;
  border-color: #cc1417;
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: #cc1417;
    border-color: #cc1417;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cc1417;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #cc1417;
    border-color: #cc1417;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.5);
}
.btn-outline-light {
  color: #eff4f5;
  border-color: #eff4f5;
}
.btn-outline-light:hover {
    color: #212529;
    background-color: #eff4f5;
    border-color: #eff4f5;
}
.btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 244, 245, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eff4f5;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #eff4f5;
    border-color: #eff4f5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 244, 245, 0.5);
}
.btn-outline-dark {
  color: #161616;
  border-color: #161616;
}
.btn-outline-dark:hover {
    color: #fff;
    background-color: #161616;
    border-color: #161616;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(22, 22, 22, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #161616;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #161616;
    border-color: #161616;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(22, 22, 22, 0.5);
}
.btn-outline-pale {
  color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-outline-pale:hover {
    color: #212529;
    background-color: #c8c8c8;
    border-color: #c8c8c8;
}
.btn-outline-pale:focus, .btn-outline-pale.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}
.btn-outline-pale.disabled, .btn-outline-pale:disabled {
    color: #c8c8c8;
    background-color: transparent;
}
.btn-outline-pale:not(:disabled):not(.disabled):active, .btn-outline-pale:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pale.dropdown-toggle {
    color: #212529;
    background-color: #c8c8c8;
    border-color: #c8c8c8;
}
.btn-outline-pale:not(:disabled):not(.disabled):active:focus, .btn-outline-pale:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pale.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #66ccff;
  text-decoration: none;
}
.btn-link:hover {
    color: #99ddff;
    text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
    text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.7rem 2.5rem;
  font-size: 0.7875rem;
  line-height: 1.25;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
.fade {
      transition: none;
}
}
.fade:not(.show) {
    opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
.collapsing {
      transition: none;
}
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 15rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #34495e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
.dropdown-menu-sm-left {
    right: auto;
    left: 0;
}
.dropdown-menu-sm-right {
    right: 0;
    left: auto;
}
}
@media (min-width: 768px) {
.dropdown-menu-md-left {
    right: auto;
    left: 0;
}
.dropdown-menu-md-right {
    right: 0;
    left: auto;
}
}
@media (min-width: 992px) {
.dropdown-menu-lg-left {
    right: auto;
    left: 0;
}
.dropdown-menu-lg-right {
    right: 0;
    left: auto;
}
}
@media (min-width: 1200px) {
.dropdown-menu-xl-left {
    right: auto;
    left: 0;
}
.dropdown-menu-xl-right {
    right: 0;
    left: auto;
}
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0 25px;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    text-decoration: none;
    background-color: #161616;
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #161616;
}
.dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 25px;
  margin-bottom: 0;
  font-size: 0.7875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0 25px;
  color: #fff;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
}
.btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 2.925rem;
  padding-left: 2.925rem;
}
.dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: 0px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}
.input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: 0px;
}
.input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}
.input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file {
    display: flex;
    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
}
.input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3;
}
.input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: 0px;
}
.input-group-prepend {
  margin-right: 0px;
}
.input-group-append {
  margin-left: 0px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0px solid #c6bec2;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 0px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 0px);
}
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.35rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.175rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #66ccff;
    background-color: #66ccff;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #e6f7ff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 0px;
}
.custom-control-label::after {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #66ccff;
  background-color: #66ccff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(102, 204, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(102, 204, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(102, 204, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
    top: calc(0.175rem + 0px);
    left: calc(-2.25rem + 0px);
    width: calc(1rem - 0px);
    height: calc(1rem - 0px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.custom-switch .custom-control-label::after {
        transition: none;
}
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(102, 204, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 0px solid #c6bec2;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-select:focus {
    border-color: #e6f7ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}
.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}
.custom-select::-ms-expand {
    display: none;
}
.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 0px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 0px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
    border-color: #e6f7ff;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 0px solid #c6bec2;
  border-radius: 0.25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-range:focus {
    outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.custom-range::-moz-focus-outer {
    border: 0;
}
.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #66ccff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
}
}
.custom-range::-webkit-slider-thumb:active {
      background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #66ccff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
}
}
.custom-range::-moz-range-thumb:active {
      background-color: white;
}
.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #66ccff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
.custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none;
}
}
.custom-range::-ms-thumb:active {
      background-color: white;
}
.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
    cursor: default;
}
.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none;
}
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
    text-decoration: none;
}
.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent;
}
.nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f5f1f5;
    border-color: #dee2e6 #dee2e6 #f5f1f5;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #66ccff;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.33125rem;
  padding-bottom: 0.33125rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 1.875rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}
.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
.navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 576px) {
.navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-sm .navbar-nav {
      flex-direction: row;
}
.navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
}
.navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
}
.navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap;
}
.navbar-expand-sm .navbar-nav-scroll {
      overflow: visible;
}
.navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
}
.navbar-expand-sm .navbar-toggler {
      display: none;
}
}
@media (max-width: 767.98px) {
.navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 768px) {
.navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-md .navbar-nav {
      flex-direction: row;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
}
.navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
}
.navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap;
}
.navbar-expand-md .navbar-nav-scroll {
      overflow: visible;
}
.navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
}
.navbar-expand-md .navbar-toggler {
      display: none;
}
}
@media (max-width: 991.98px) {
.navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 992px) {
.navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-lg .navbar-nav {
      flex-direction: row;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
}
.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
}
.navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap;
}
.navbar-expand-lg .navbar-nav-scroll {
      overflow: visible;
}
.navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
}
.navbar-expand-lg .navbar-toggler {
      display: none;
}
}
@media (max-width: 1199.98px) {
.navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
}
}
@media (min-width: 1200px) {
.navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand-xl .navbar-nav {
      flex-direction: row;
}
.navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
}
.navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
}
.navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap;
}
.navbar-expand-xl .navbar-nav-scroll {
      overflow: visible;
}
.navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
}
.navbar-expand-xl .navbar-toggler {
      display: none;
}
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav {
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
      position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
}
.navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
    overflow: visible;
}
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
    color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}
.card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
.card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
}
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
.card-group {
    display: flex;
    flex-flow: row wrap;
}
.card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0;
}
.card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
}
.card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
}
.card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0;
}
.card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0;
}
.card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
}
.card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0;
}
.card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0;
}
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
.card-columns {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
}
.card-columns .card {
      display: inline-block;
      width: 100%;
}
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
    overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
}
.accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #66ccff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
    z-index: 2;
    color: #99ddff;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #66ccff;
  border-color: #66ccff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.28rem 0.4em;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.badge {
      transition: none;
}
}
a.badge:hover, a.badge:focus {
    text-decoration: none;
}
.badge:empty {
    display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #212529;
  background-color: #66ccff;
}
a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #33bbff;
}
a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.5);
}
.badge-secondary {
  color: #212529;
  background-color: #fff;
}
a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e6e6e6;
}
a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #33cc00;
}
a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #269900;
}
a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.5);
}
.badge-info {
  color: #212529;
  background-color: #4ab2e3;
}
a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #219ed9;
}
a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(74, 178, 227, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #f6a336;
}
a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ee8c0b;
}
a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(246, 163, 54, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #cc1417;
}
a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #9e0f12;
}
a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #eff4f5;
}
a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d0dee1;
}
a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 244, 245, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #161616;
}
a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black;
}
a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 22, 22, 0.5);
}
.badge-pale {
  color: #212529;
  background-color: #c8c8c8;
}
a.badge-pale:hover, a.badge-pale:focus {
    color: #212529;
    background-color: #afafaf;
}
a.badge-pale:focus, a.badge-pale.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
.jumbotron {
      padding: 4rem 2rem;
}
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1.25rem;
  border: 0 solid transparent;
  border-radius: 0;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3.85rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
}
.alert-primary {
  color: #356a85;
  background-color: #e0f5ff;
  border-color: #d4f1ff;
}
.alert-primary hr {
    border-top-color: #bbe9ff;
}
.alert-primary .alert-link {
    color: #264d61;
}
.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-secondary hr {
    border-top-color: #f2f2f2;
}
.alert-secondary .alert-link {
    color: #6c6c6c;
}
.alert-success {
  color: #1b6a00;
  background-color: #d6f5cc;
  border-color: #c6f1b8;
}
.alert-success hr {
    border-top-color: #b5eda3;
}
.alert-success .alert-link {
    color: #0e3700;
}
.alert-info {
  color: #265d76;
  background-color: #dbf0f9;
  border-color: #cce9f7;
}
.alert-info hr {
    border-top-color: #b6dff4;
}
.alert-info .alert-link {
    color: #1a3f4f;
}
.alert-warning {
  color: #80551c;
  background-color: #fdedd7;
  border-color: #fce5c7;
}
.alert-warning hr {
    border-top-color: #fbdaaf;
}
.alert-warning .alert-link {
    color: #563913;
}
.alert-danger {
  color: #6a0a0c;
  background-color: #f5d0d1;
  border-color: #f1bdbe;
}
.alert-danger hr {
    border-top-color: #eda8a9;
}
.alert-danger .alert-link {
    color: #3b0607;
}
.alert-light {
  color: #7c7f7f;
  background-color: #fcfdfd;
  border-color: #fbfcfc;
}
.alert-light hr {
    border-top-color: #ecf1f1;
}
.alert-light .alert-link {
    color: #636565;
}
.alert-dark {
  color: #0b0b0b;
  background-color: #d0d0d0;
  border-color: #bebebe;
}
.alert-dark hr {
    border-top-color: #b1b1b1;
}
.alert-dark .alert-link {
    color: black;
}
.alert-pale {
  color: #686868;
  background-color: #f4f4f4;
  border-color: #f0f0f0;
}
.alert-pale hr {
    border-top-color: #e3e3e3;
}
.alert-pale .alert-link {
    color: #4f4f4f;
}
@-webkit-keyframes progress-bar-stripes {
from {
    background-position: 1rem 0;
}
to {
    background-position: 0 0;
}
}
@keyframes progress-bar-stripes {
from {
    background-position: 1rem 0;
}
to {
    background-position: 0 0;
}
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #66ccff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
.progress-bar {
      transition: none;
}
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
.progress-bar-animated {
      -webkit-animation: none;
              animation: none;
}
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}
.list-group-item-action:active {
    color: #34495e;
    background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #66ccff;
    border-color: #66ccff;
}
.list-group-item + .list-group-item {
    border-top-width: 0;
}
.list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px;
}
@media (min-width: 576px) {
.list-group-horizontal-sm {
    flex-direction: row;
}
.list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
}
.list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
}
.list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0;
}
.list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
}
.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
}
}
@media (min-width: 768px) {
.list-group-horizontal-md {
    flex-direction: row;
}
.list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
}
.list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
}
.list-group-horizontal-md > .list-group-item.active {
      margin-top: 0;
}
.list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
}
.list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
}
}
@media (min-width: 992px) {
.list-group-horizontal-lg {
    flex-direction: row;
}
.list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
}
.list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
}
.list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0;
}
.list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
}
.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
}
}
@media (min-width: 1200px) {
.list-group-horizontal-xl {
    flex-direction: row;
}
.list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
}
.list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
}
.list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0;
}
.list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0;
}
.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
}
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
    border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0;
}
.list-group-item-primary {
  color: #356a85;
  background-color: #d4f1ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #356a85;
    background-color: #bbe9ff;
}
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #356a85;
    border-color: #356a85;
}
.list-group-item-secondary {
  color: #858585;
  background-color: white;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585;
}
.list-group-item-success {
  color: #1b6a00;
  background-color: #c6f1b8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1b6a00;
    background-color: #b5eda3;
}
.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1b6a00;
    border-color: #1b6a00;
}
.list-group-item-info {
  color: #265d76;
  background-color: #cce9f7;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #265d76;
    background-color: #b6dff4;
}
.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #265d76;
    border-color: #265d76;
}
.list-group-item-warning {
  color: #80551c;
  background-color: #fce5c7;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #80551c;
    background-color: #fbdaaf;
}
.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #80551c;
    border-color: #80551c;
}
.list-group-item-danger {
  color: #6a0a0c;
  background-color: #f1bdbe;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6a0a0c;
    background-color: #eda8a9;
}
.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6a0a0c;
    border-color: #6a0a0c;
}
.list-group-item-light {
  color: #7c7f7f;
  background-color: #fbfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7f7f;
    background-color: #ecf1f1;
}
.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7f7f;
    border-color: #7c7f7f;
}
.list-group-item-dark {
  color: #0b0b0b;
  background-color: #bebebe;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0b0b0b;
    background-color: #b1b1b1;
}
.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0b0b0b;
    border-color: #0b0b0b;
}
.list-group-item-pale {
  color: #686868;
  background-color: #f0f0f0;
}
.list-group-item-pale.list-group-item-action:hover, .list-group-item-pale.list-group-item-action:focus {
    color: #686868;
    background-color: #e3e3e3;
}
.list-group-item-pale.list-group-item-action.active {
    color: #fff;
    background-color: #686868;
    border-color: #686868;
}
.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:hover {
    color: #000;
    text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.9rem;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
  opacity: 0;
  border-radius: 0.45rem;
}
.toast:not(:last-child) {
    margin-bottom: 1.15rem;
}
.toast.showing {
    opacity: 1;
}
.toast.show {
    display: block;
    opacity: 1;
}
.toast.hide {
    display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.15rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.45rem - 1px);
  border-top-right-radius: calc(0.45rem - 1px);
}
.toast-body {
  padding: 1.15rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
.modal.fade .modal-dialog {
        transition: none;
}
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
      content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1.5625rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
    padding: 0 1.5625rem;
    margin: 0 -1.5625rem 0 auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
    margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
.modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
}
.modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
}
.modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem);
}
.modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
}
.modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
}
.modal-sm {
    max-width: 300px;
}
}
@media (min-width: 992px) {
.modal-lg,
  .modal-xl {
    max-width: 1250px;
}
}
@media (min-width: 1200px) {
.modal-xl {
    max-width: 1140px;
}
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
    opacity: 0.9;
}
.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}
.tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
}
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"], .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #171114;
}
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"], .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #171114;
}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"], .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #171114;
}
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"], .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #171114;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #171114;
  border-radius: 0.45rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 400px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9rem;
  word-wrap: break-word;
  background-color: #171114;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.45rem;
}
.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    margin: 0 0.45rem;
}
.popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
}
.bs-popover-top, .bs-popover-auto[x-placement^="top"], .b-popover-primary.bs-popover-auto[x-placement^="top"], .b-popover-secondary.bs-popover-auto[x-placement^="top"], .b-popover-success.bs-popover-auto[x-placement^="top"], .b-popover-info.bs-popover-auto[x-placement^="top"], .b-popover-warning.bs-popover-auto[x-placement^="top"], .b-popover-danger.bs-popover-auto[x-placement^="top"], .b-popover-light.bs-popover-auto[x-placement^="top"], .b-popover-dark.bs-popover-auto[x-placement^="top"], .b-popover-pale.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 1rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: -1rem;
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 1rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 0;
      border-width: 1rem 0.5rem 0;
      border-top-color: transparent;
}
.bs-popover-right, .bs-popover-auto[x-placement^="right"], .b-popover-primary.bs-popover-auto[x-placement^="right"], .b-popover-secondary.bs-popover-auto[x-placement^="right"], .b-popover-success.bs-popover-auto[x-placement^="right"], .b-popover-info.bs-popover-auto[x-placement^="right"], .b-popover-warning.bs-popover-auto[x-placement^="right"], .b-popover-danger.bs-popover-auto[x-placement^="right"], .b-popover-light.bs-popover-auto[x-placement^="right"], .b-popover-dark.bs-popover-auto[x-placement^="right"], .b-popover-pale.bs-popover-auto[x-placement^="right"] {
  margin-left: 1rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: -1rem;
    width: 1rem;
    height: 1rem;
    margin: 0.45rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 1rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 0;
      border-width: 0.5rem 1rem 0.5rem 0;
      border-right-color: transparent;
}
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"], .b-popover-primary.bs-popover-auto[x-placement^="bottom"], .b-popover-secondary.bs-popover-auto[x-placement^="bottom"], .b-popover-success.bs-popover-auto[x-placement^="bottom"], .b-popover-info.bs-popover-auto[x-placement^="bottom"], .b-popover-warning.bs-popover-auto[x-placement^="bottom"], .b-popover-danger.bs-popover-auto[x-placement^="bottom"], .b-popover-light.bs-popover-auto[x-placement^="bottom"], .b-popover-dark.bs-popover-auto[x-placement^="bottom"], .b-popover-pale.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 1rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: -1rem;
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 1rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 0;
      border-width: 0 0.5rem 1rem 0.5rem;
      border-bottom-color: transparent;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 0 solid #171114;
}
.bs-popover-left, .bs-popover-auto[x-placement^="left"], .b-popover-primary.bs-popover-auto[x-placement^="left"], .b-popover-secondary.bs-popover-auto[x-placement^="left"], .b-popover-success.bs-popover-auto[x-placement^="left"], .b-popover-info.bs-popover-auto[x-placement^="left"], .b-popover-warning.bs-popover-auto[x-placement^="left"], .b-popover-danger.bs-popover-auto[x-placement^="left"], .b-popover-light.bs-popover-auto[x-placement^="left"], .b-popover-dark.bs-popover-auto[x-placement^="left"], .b-popover-pale.bs-popover-auto[x-placement^="left"] {
  margin-right: 1rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: -1rem;
    width: 1rem;
    height: 1rem;
    margin: 0.45rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 1rem;
      border-left-color: rgba(0, 0, 0, 0.05);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 0;
      border-width: 0.5rem 0 0.5rem 1rem;
      border-left-color: transparent;
}
.popover-header {
  padding: 0 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #fff;
  background-color: #171114;
  border-bottom: 0 solid #080607;
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem;
}
.popover-header:empty {
    display: none;
}
.popover-body {
  padding: 0 0.75rem;
  color: #fff;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.carousel-item {
      transition: none;
}
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
.carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none;
}
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
.carousel-control-prev,
    .carousel-control-next {
      transition: none;
}
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
.carousel-indicators li {
        transition: none;
}
}
.carousel-indicators .active {
    opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
to {
    transform: rotate(360deg);
}
}
@keyframes spinner-border {
to {
    transform: rotate(360deg);
}
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
0% {
    transform: scale(0);
}
50% {
    opacity: 1;
    transform: none;
}
}
@keyframes spinner-grow {
0% {
    transform: scale(0);
}
50% {
    opacity: 1;
    transform: none;
}
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
.spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #66ccff !important;
}
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #33bbff !important;
}
.bg-secondary {
  background-color: #fff !important;
}
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}
.bg-success {
  background-color: #33cc00 !important;
}
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #269900 !important;
}
.bg-info {
  background-color: #4ab2e3 !important;
}
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #219ed9 !important;
}
.bg-warning {
  background-color: #f6a336 !important;
}
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ee8c0b !important;
}
.bg-danger {
  background-color: #cc1417 !important;
}
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9e0f12 !important;
}
.bg-light {
  background-color: #eff4f5 !important;
}
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d0dee1 !important;
}
.bg-dark {
  background-color: #161616 !important;
}
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}
.bg-pale {
  background-color: #c8c8c8 !important;
}
a.bg-pale:hover, a.bg-pale:focus,
button.bg-pale:hover,
button.bg-pale:focus {
  background-color: #afafaf !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #66ccff !important;
}
.border-secondary {
  border-color: #fff !important;
}
.border-success {
  border-color: #33cc00 !important;
}
.border-info {
  border-color: #4ab2e3 !important;
}
.border-warning {
  border-color: #f6a336 !important;
}
.border-danger {
  border-color: #cc1417 !important;
}
.border-light {
  border-color: #eff4f5 !important;
}
.border-dark {
  border-color: #161616 !important;
}
.border-pale {
  border-color: #c8c8c8 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
.d-sm-none {
    display: none !important;
}
.d-sm-inline {
    display: inline !important;
}
.d-sm-inline-block {
    display: inline-block !important;
}
.d-sm-block {
    display: block !important;
}
.d-sm-table {
    display: table !important;
}
.d-sm-table-row {
    display: table-row !important;
}
.d-sm-table-cell {
    display: table-cell !important;
}
.d-sm-flex {
    display: flex !important;
}
.d-sm-inline-flex {
    display: inline-flex !important;
}
}
@media (min-width: 768px) {
.d-md-none {
    display: none !important;
}
.d-md-inline {
    display: inline !important;
}
.d-md-inline-block {
    display: inline-block !important;
}
.d-md-block {
    display: block !important;
}
.d-md-table {
    display: table !important;
}
.d-md-table-row {
    display: table-row !important;
}
.d-md-table-cell {
    display: table-cell !important;
}
.d-md-flex {
    display: flex !important;
}
.d-md-inline-flex {
    display: inline-flex !important;
}
}
@media (min-width: 992px) {
.d-lg-none {
    display: none !important;
}
.d-lg-inline {
    display: inline !important;
}
.d-lg-inline-block {
    display: inline-block !important;
}
.d-lg-block {
    display: block !important;
}
.d-lg-table {
    display: table !important;
}
.d-lg-table-row {
    display: table-row !important;
}
.d-lg-table-cell {
    display: table-cell !important;
}
.d-lg-flex {
    display: flex !important;
}
.d-lg-inline-flex {
    display: inline-flex !important;
}
}
@media (min-width: 1200px) {
.d-xl-none {
    display: none !important;
}
.d-xl-inline {
    display: inline !important;
}
.d-xl-inline-block {
    display: inline-block !important;
}
.d-xl-block {
    display: block !important;
}
.d-xl-table {
    display: table !important;
}
.d-xl-table-row {
    display: table-row !important;
}
.d-xl-table-cell {
    display: table-cell !important;
}
.d-xl-flex {
    display: flex !important;
}
.d-xl-inline-flex {
    display: inline-flex !important;
}
}
@media print {
.d-print-none {
    display: none !important;
}
.d-print-inline {
    display: inline !important;
}
.d-print-inline-block {
    display: inline-block !important;
}
.d-print-block {
    display: block !important;
}
.d-print-table {
    display: table !important;
}
.d-print-table-row {
    display: table-row !important;
}
.d-print-table-cell {
    display: table-cell !important;
}
.d-print-flex {
    display: flex !important;
}
.d-print-inline-flex {
    display: inline-flex !important;
}
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
    display: block;
    content: "";
}
.embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
.flex-sm-row {
    flex-direction: row !important;
}
.flex-sm-column {
    flex-direction: column !important;
}
.flex-sm-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-sm-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-sm-wrap {
    flex-wrap: wrap !important;
}
.flex-sm-nowrap {
    flex-wrap: nowrap !important;
}
.flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-sm-fill {
    flex: 1 1 auto !important;
}
.flex-sm-grow-0 {
    flex-grow: 0 !important;
}
.flex-sm-grow-1 {
    flex-grow: 1 !important;
}
.flex-sm-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-sm-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-sm-start {
    justify-content: flex-start !important;
}
.justify-content-sm-end {
    justify-content: flex-end !important;
}
.justify-content-sm-center {
    justify-content: center !important;
}
.justify-content-sm-between {
    justify-content: space-between !important;
}
.justify-content-sm-around {
    justify-content: space-around !important;
}
.align-items-sm-start {
    align-items: flex-start !important;
}
.align-items-sm-end {
    align-items: flex-end !important;
}
.align-items-sm-center {
    align-items: center !important;
}
.align-items-sm-baseline {
    align-items: baseline !important;
}
.align-items-sm-stretch {
    align-items: stretch !important;
}
.align-content-sm-start {
    align-content: flex-start !important;
}
.align-content-sm-end {
    align-content: flex-end !important;
}
.align-content-sm-center {
    align-content: center !important;
}
.align-content-sm-between {
    align-content: space-between !important;
}
.align-content-sm-around {
    align-content: space-around !important;
}
.align-content-sm-stretch {
    align-content: stretch !important;
}
.align-self-sm-auto {
    align-self: auto !important;
}
.align-self-sm-start {
    align-self: flex-start !important;
}
.align-self-sm-end {
    align-self: flex-end !important;
}
.align-self-sm-center {
    align-self: center !important;
}
.align-self-sm-baseline {
    align-self: baseline !important;
}
.align-self-sm-stretch {
    align-self: stretch !important;
}
}
@media (min-width: 768px) {
.flex-md-row {
    flex-direction: row !important;
}
.flex-md-column {
    flex-direction: column !important;
}
.flex-md-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-md-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-md-wrap {
    flex-wrap: wrap !important;
}
.flex-md-nowrap {
    flex-wrap: nowrap !important;
}
.flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-md-fill {
    flex: 1 1 auto !important;
}
.flex-md-grow-0 {
    flex-grow: 0 !important;
}
.flex-md-grow-1 {
    flex-grow: 1 !important;
}
.flex-md-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-md-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-md-start {
    justify-content: flex-start !important;
}
.justify-content-md-end {
    justify-content: flex-end !important;
}
.justify-content-md-center {
    justify-content: center !important;
}
.justify-content-md-between {
    justify-content: space-between !important;
}
.justify-content-md-around {
    justify-content: space-around !important;
}
.align-items-md-start {
    align-items: flex-start !important;
}
.align-items-md-end {
    align-items: flex-end !important;
}
.align-items-md-center {
    align-items: center !important;
}
.align-items-md-baseline {
    align-items: baseline !important;
}
.align-items-md-stretch {
    align-items: stretch !important;
}
.align-content-md-start {
    align-content: flex-start !important;
}
.align-content-md-end {
    align-content: flex-end !important;
}
.align-content-md-center {
    align-content: center !important;
}
.align-content-md-between {
    align-content: space-between !important;
}
.align-content-md-around {
    align-content: space-around !important;
}
.align-content-md-stretch {
    align-content: stretch !important;
}
.align-self-md-auto {
    align-self: auto !important;
}
.align-self-md-start {
    align-self: flex-start !important;
}
.align-self-md-end {
    align-self: flex-end !important;
}
.align-self-md-center {
    align-self: center !important;
}
.align-self-md-baseline {
    align-self: baseline !important;
}
.align-self-md-stretch {
    align-self: stretch !important;
}
}
@media (min-width: 992px) {
.flex-lg-row {
    flex-direction: row !important;
}
.flex-lg-column {
    flex-direction: column !important;
}
.flex-lg-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-lg-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-lg-wrap {
    flex-wrap: wrap !important;
}
.flex-lg-nowrap {
    flex-wrap: nowrap !important;
}
.flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-lg-fill {
    flex: 1 1 auto !important;
}
.flex-lg-grow-0 {
    flex-grow: 0 !important;
}
.flex-lg-grow-1 {
    flex-grow: 1 !important;
}
.flex-lg-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-lg-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-lg-start {
    justify-content: flex-start !important;
}
.justify-content-lg-end {
    justify-content: flex-end !important;
}
.justify-content-lg-center {
    justify-content: center !important;
}
.justify-content-lg-between {
    justify-content: space-between !important;
}
.justify-content-lg-around {
    justify-content: space-around !important;
}
.align-items-lg-start {
    align-items: flex-start !important;
}
.align-items-lg-end {
    align-items: flex-end !important;
}
.align-items-lg-center {
    align-items: center !important;
}
.align-items-lg-baseline {
    align-items: baseline !important;
}
.align-items-lg-stretch {
    align-items: stretch !important;
}
.align-content-lg-start {
    align-content: flex-start !important;
}
.align-content-lg-end {
    align-content: flex-end !important;
}
.align-content-lg-center {
    align-content: center !important;
}
.align-content-lg-between {
    align-content: space-between !important;
}
.align-content-lg-around {
    align-content: space-around !important;
}
.align-content-lg-stretch {
    align-content: stretch !important;
}
.align-self-lg-auto {
    align-self: auto !important;
}
.align-self-lg-start {
    align-self: flex-start !important;
}
.align-self-lg-end {
    align-self: flex-end !important;
}
.align-self-lg-center {
    align-self: center !important;
}
.align-self-lg-baseline {
    align-self: baseline !important;
}
.align-self-lg-stretch {
    align-self: stretch !important;
}
}
@media (min-width: 1200px) {
.flex-xl-row {
    flex-direction: row !important;
}
.flex-xl-column {
    flex-direction: column !important;
}
.flex-xl-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-xl-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-xl-wrap {
    flex-wrap: wrap !important;
}
.flex-xl-nowrap {
    flex-wrap: nowrap !important;
}
.flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-xl-fill {
    flex: 1 1 auto !important;
}
.flex-xl-grow-0 {
    flex-grow: 0 !important;
}
.flex-xl-grow-1 {
    flex-grow: 1 !important;
}
.flex-xl-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-xl-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-xl-start {
    justify-content: flex-start !important;
}
.justify-content-xl-end {
    justify-content: flex-end !important;
}
.justify-content-xl-center {
    justify-content: center !important;
}
.justify-content-xl-between {
    justify-content: space-between !important;
}
.justify-content-xl-around {
    justify-content: space-around !important;
}
.align-items-xl-start {
    align-items: flex-start !important;
}
.align-items-xl-end {
    align-items: flex-end !important;
}
.align-items-xl-center {
    align-items: center !important;
}
.align-items-xl-baseline {
    align-items: baseline !important;
}
.align-items-xl-stretch {
    align-items: stretch !important;
}
.align-content-xl-start {
    align-content: flex-start !important;
}
.align-content-xl-end {
    align-content: flex-end !important;
}
.align-content-xl-center {
    align-content: center !important;
}
.align-content-xl-between {
    align-content: space-between !important;
}
.align-content-xl-around {
    align-content: space-around !important;
}
.align-content-xl-stretch {
    align-content: stretch !important;
}
.align-self-xl-auto {
    align-self: auto !important;
}
.align-self-xl-start {
    align-self: flex-start !important;
}
.align-self-xl-end {
    align-self: flex-end !important;
}
.align-self-xl-center {
    align-self: center !important;
}
.align-self-xl-baseline {
    align-self: baseline !important;
}
.align-self-xl-stretch {
    align-self: stretch !important;
}
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
.float-sm-left {
    float: left !important;
}
.float-sm-right {
    float: right !important;
}
.float-sm-none {
    float: none !important;
}
}
@media (min-width: 768px) {
.float-md-left {
    float: left !important;
}
.float-md-right {
    float: right !important;
}
.float-md-none {
    float: none !important;
}
}
@media (min-width: 992px) {
.float-lg-left {
    float: left !important;
}
.float-lg-right {
    float: right !important;
}
.float-lg-none {
    float: none !important;
}
}
@media (min-width: 1200px) {
.float-xl-left {
    float: left !important;
}
.float-xl-right {
    float: right !important;
}
.float-xl-none {
    float: none !important;
}
}
.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
}
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4, .first-td-padding td:first-child,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
.m-sm-0 {
    margin: 0 !important;
}
.mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
}
.mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
}
.mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
}
.ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
}
.m-sm-1 {
    margin: 0.25rem !important;
}
.mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
}
.mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
}
.mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
}
.ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
}
.m-sm-2 {
    margin: 0.5rem !important;
}
.mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
}
.mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
}
.mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
}
.ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
}
.m-sm-3 {
    margin: 1rem !important;
}
.mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
}
.mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
}
.mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
}
.ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
}
.m-sm-4 {
    margin: 1.5rem !important;
}
.mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
}
.mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
}
.mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
}
.ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
}
.m-sm-5 {
    margin: 3rem !important;
}
.mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
}
.mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
}
.mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
}
.ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
}
.p-sm-0 {
    padding: 0 !important;
}
.pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
}
.pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
}
.pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
}
.pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
}
.p-sm-1 {
    padding: 0.25rem !important;
}
.pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
}
.pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
}
.pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
}
.pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
}
.p-sm-2 {
    padding: 0.5rem !important;
}
.pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
}
.pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
}
.pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
}
.pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
}
.p-sm-3 {
    padding: 1rem !important;
}
.pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
}
.pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
}
.pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
}
.pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
}
.p-sm-4 {
    padding: 1.5rem !important;
}
.pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
}
.pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
}
.pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
}
.pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
}
.p-sm-5 {
    padding: 3rem !important;
}
.pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
}
.pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
}
.pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
}
.pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
}
.m-sm-n1 {
    margin: -0.25rem !important;
}
.mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
}
.mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
}
.mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
}
.ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
}
.m-sm-n2 {
    margin: -0.5rem !important;
}
.mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
}
.mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
}
.mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
}
.ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
}
.m-sm-n3 {
    margin: -1rem !important;
}
.mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
}
.mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
}
.mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
}
.ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
}
.m-sm-n4 {
    margin: -1.5rem !important;
}
.mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
}
.mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
}
.mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
}
.ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
}
.m-sm-n5 {
    margin: -3rem !important;
}
.mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
}
.mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
}
.mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
}
.ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
}
.m-sm-auto {
    margin: auto !important;
}
.mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
}
.mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
}
.mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
}
.ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
}
}
@media (min-width: 768px) {
.m-md-0 {
    margin: 0 !important;
}
.mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
}
.mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
}
.mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
}
.ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
}
.m-md-1 {
    margin: 0.25rem !important;
}
.mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
}
.mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
}
.mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
}
.ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
}
.m-md-2 {
    margin: 0.5rem !important;
}
.mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
}
.mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
}
.mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
}
.ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
}
.m-md-3 {
    margin: 1rem !important;
}
.mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
}
.mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
}
.mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
}
.ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
}
.m-md-4 {
    margin: 1.5rem !important;
}
.mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
}
.mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
}
.mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
}
.ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
}
.m-md-5 {
    margin: 3rem !important;
}
.mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
}
.mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
}
.mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
}
.ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
}
.p-md-0 {
    padding: 0 !important;
}
.pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
}
.pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
}
.pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
}
.pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
}
.p-md-1 {
    padding: 0.25rem !important;
}
.pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
}
.pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
}
.pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
}
.pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
}
.p-md-2 {
    padding: 0.5rem !important;
}
.pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
}
.pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
}
.pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
}
.pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
}
.p-md-3 {
    padding: 1rem !important;
}
.pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
}
.pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
}
.pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
}
.pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
}
.p-md-4 {
    padding: 1.5rem !important;
}
.pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
}
.pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
}
.pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
}
.pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
}
.p-md-5 {
    padding: 3rem !important;
}
.pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
}
.pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
}
.pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
}
.pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
}
.m-md-n1 {
    margin: -0.25rem !important;
}
.mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
}
.mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
}
.mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
}
.ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
}
.m-md-n2 {
    margin: -0.5rem !important;
}
.mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
}
.mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
}
.mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
}
.ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
}
.m-md-n3 {
    margin: -1rem !important;
}
.mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
}
.mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
}
.mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
}
.ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
}
.m-md-n4 {
    margin: -1.5rem !important;
}
.mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
}
.mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
}
.mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
}
.ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
}
.m-md-n5 {
    margin: -3rem !important;
}
.mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
}
.mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
}
.mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
}
.ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
}
.m-md-auto {
    margin: auto !important;
}
.mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
}
.mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
}
.mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
}
.ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
}
}
@media (min-width: 992px) {
.m-lg-0 {
    margin: 0 !important;
}
.mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
}
.mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
}
.mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
}
.ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
}
.m-lg-1 {
    margin: 0.25rem !important;
}
.mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
}
.mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
}
.mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
}
.ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
}
.m-lg-2 {
    margin: 0.5rem !important;
}
.mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
}
.mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
}
.mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
}
.ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
}
.m-lg-3 {
    margin: 1rem !important;
}
.mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
}
.mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
}
.mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
}
.ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
}
.m-lg-4 {
    margin: 1.5rem !important;
}
.mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
}
.mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
}
.mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
}
.ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
}
.m-lg-5 {
    margin: 3rem !important;
}
.mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
}
.mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
}
.mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
}
.ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
}
.p-lg-0 {
    padding: 0 !important;
}
.pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
}
.pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
}
.pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
}
.pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
}
.p-lg-1 {
    padding: 0.25rem !important;
}
.pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
}
.pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
}
.pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
}
.pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
}
.p-lg-2 {
    padding: 0.5rem !important;
}
.pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
}
.pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
}
.pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
}
.pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
}
.p-lg-3 {
    padding: 1rem !important;
}
.pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
}
.pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
}
.pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
}
.pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
}
.p-lg-4 {
    padding: 1.5rem !important;
}
.pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
}
.pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
}
.pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
}
.pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
}
.p-lg-5 {
    padding: 3rem !important;
}
.pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
}
.pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
}
.pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
}
.pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
}
.m-lg-n1 {
    margin: -0.25rem !important;
}
.mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
}
.mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
}
.mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
}
.ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
}
.m-lg-n2 {
    margin: -0.5rem !important;
}
.mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
}
.mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
}
.mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
}
.ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
}
.m-lg-n3 {
    margin: -1rem !important;
}
.mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
}
.mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
}
.mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
}
.ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
}
.m-lg-n4 {
    margin: -1.5rem !important;
}
.mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
}
.mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
}
.mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
}
.ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
}
.m-lg-n5 {
    margin: -3rem !important;
}
.mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
}
.mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
}
.mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
}
.ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
}
.m-lg-auto {
    margin: auto !important;
}
.mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
}
.mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
}
.mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
}
.ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
}
}
@media (min-width: 1200px) {
.m-xl-0 {
    margin: 0 !important;
}
.mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
}
.mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
}
.mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
}
.ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
}
.m-xl-1 {
    margin: 0.25rem !important;
}
.mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
}
.mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
}
.mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
}
.ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
}
.m-xl-2 {
    margin: 0.5rem !important;
}
.mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
}
.mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
}
.mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
}
.ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
}
.m-xl-3 {
    margin: 1rem !important;
}
.mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
}
.mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
}
.mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
}
.ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
}
.m-xl-4 {
    margin: 1.5rem !important;
}
.mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
}
.mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
}
.mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
}
.ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
}
.m-xl-5 {
    margin: 3rem !important;
}
.mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
}
.mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
}
.mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
}
.ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
}
.p-xl-0 {
    padding: 0 !important;
}
.pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
}
.pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
}
.pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
}
.pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
}
.p-xl-1 {
    padding: 0.25rem !important;
}
.pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
}
.pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
}
.pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
}
.pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
}
.p-xl-2 {
    padding: 0.5rem !important;
}
.pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
}
.pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
}
.pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
}
.pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
}
.p-xl-3 {
    padding: 1rem !important;
}
.pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
}
.pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
}
.pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
}
.pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
}
.p-xl-4 {
    padding: 1.5rem !important;
}
.pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
}
.pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
}
.pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
}
.pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
}
.p-xl-5 {
    padding: 3rem !important;
}
.pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
}
.pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
}
.pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
}
.pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
}
.m-xl-n1 {
    margin: -0.25rem !important;
}
.mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
}
.mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
}
.mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
}
.ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
}
.m-xl-n2 {
    margin: -0.5rem !important;
}
.mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
}
.mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
}
.mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
}
.ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
}
.m-xl-n3 {
    margin: -1rem !important;
}
.mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
}
.mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
}
.mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
}
.ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
}
.m-xl-n4 {
    margin: -1.5rem !important;
}
.mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
}
.mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
}
.mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
}
.ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
}
.m-xl-n5 {
    margin: -3rem !important;
}
.mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
}
.mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
}
.mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
}
.ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
}
.m-xl-auto {
    margin: auto !important;
}
.mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
}
.mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
}
.mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
}
.ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
}
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
.text-sm-left {
    text-align: left !important;
}
.text-sm-right {
    text-align: right !important;
}
.text-sm-center {
    text-align: center !important;
}
}
@media (min-width: 768px) {
.text-md-left {
    text-align: left !important;
}
.text-md-right {
    text-align: right !important;
}
.text-md-center {
    text-align: center !important;
}
}
@media (min-width: 992px) {
.text-lg-left {
    text-align: left !important;
}
.text-lg-right {
    text-align: right !important;
}
.text-lg-center {
    text-align: center !important;
}
}
@media (min-width: 1200px) {
.text-xl-left {
    text-align: left !important;
}
.text-xl-right {
    text-align: right !important;
}
.text-xl-center {
    text-align: center !important;
}
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #66ccff !important;
}
a.text-primary:hover, a.text-primary:focus {
  color: #1ab3ff !important;
}
.text-secondary {
  color: #fff !important;
}
a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important;
}
.text-success {
  color: #33cc00 !important;
}
a.text-success:hover, a.text-success:focus {
  color: #208000 !important;
}
.text-info {
  color: #4ab2e3 !important;
}
a.text-info:hover, a.text-info:focus {
  color: #1e8ec2 !important;
}
.text-warning {
  color: #f6a336 !important;
}
a.text-warning:hover, a.text-warning:focus {
  color: #d67e0a !important;
}
.text-danger {
  color: #cc1417 !important;
}
a.text-danger:hover, a.text-danger:focus {
  color: #860d0f !important;
}
.text-light {
  color: #eff4f5 !important;
}
a.text-light:hover, a.text-light:focus {
  color: #c0d4d8 !important;
}
.text-dark {
  color: #161616 !important;
}
a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}
.text-pale {
  color: #c8c8c8 !important;
}
a.text-pale:hover, a.text-pale:focus {
  color: #a2a2a2 !important;
}
.text-body {
  color: #34495e !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
*,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
}
a:not(.btn) {
    text-decoration: underline;
}
abbr[title]::after {
    content: " (" attr(title) ")";
}
pre {
    white-space: pre-wrap !important;
}
pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
}
thead {
    display: table-header-group;
}
tr,
  img {
    page-break-inside: avoid;
}
p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
}
h2,
  h3 {
    page-break-after: avoid;
}
@page {
    size: a3;
}
body {
    min-width: 992px !important;
}
.container {
    min-width: 992px !important;
}
.navbar {
    display: none;
}
.badge {
    border: 1px solid #000;
}
.table {
    border-collapse: collapse !important;
}
.table td,
    .table th {
      background-color: #fff !important;
}
.table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
}
.table-dark {
    color: inherit;
}
.table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eceeef;
}
.table .thead-dark th {
    color: inherit;
    border-color: #eceeef;
}
}
.bv-no-focus-ring:focus {
  outline: none;
}
@media (max-width: 575.98px) {
.bv-d-xs-down-none {
    display: none !important;
}
}
@media (max-width: 767.98px) {
.bv-d-sm-down-none {
    display: none !important;
}
}
@media (max-width: 991.98px) {
.bv-d-md-down-none {
    display: none !important;
}
}
@media (max-width: 1199.98px) {
.bv-d-lg-down-none {
    display: none !important;
}
}
.bv-d-xl-down-none {
  display: none !important;
}
.form-control.focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6f7ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.form-control.focus.is-valid {
    border-color: #33cc00;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.form-control.focus.is-invalid {
    border-color: #cc1417;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.b-avatar:focus {
    outline: 0;
}
.b-avatar.btn, .b-avatar[href] {
    padding: 0;
    border: 0;
}
.b-avatar.btn .b-avatar-img img, .b-avatar[href] .b-avatar-img img {
      transition: transform 0.15s ease-in-out;
}
.b-avatar.btn:not(:disabled):not(.disabled), .b-avatar[href]:not(:disabled):not(.disabled) {
      cursor: pointer;
}
.b-avatar.btn:not(:disabled):not(.disabled):hover .b-avatar-img img, .b-avatar[href]:not(:disabled):not(.disabled):hover .b-avatar-img img {
        transform: scale(1.15);
}
.b-avatar.disabled, .b-avatar:disabled, .b-avatar[disabled] {
    opacity: 0.65;
    pointer-events: none;
}
.b-avatar .b-avatar-custom,
  .b-avatar .b-avatar-text,
  .b-avatar .b-avatar-img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
}
.b-avatar .b-avatar-text {
    text-transform: uppercase;
    white-space: nowrap;
}
.b-avatar[href] {
    text-decoration: none;
}
.b-avatar > .b-icon {
    width: 60%;
    height: auto;
    max-width: 100%;
}
.b-avatar .b-avatar-img img {
    width: 100%;
    height: 100%;
    max-height: auto;
    border-radius: inherit;
    -o-object-fit: cover;
       object-fit: cover;
}
.b-avatar .b-avatar-badge {
    position: absolute;
    min-height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
    line-height: 1;
    border-radius: 10em;
    font-size: 70%;
    font-weight: 700;
    z-index: 1;
}
.b-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}
.b-avatar-sm .b-avatar-text {
    font-size: calc(0.6rem);
}
.b-avatar-sm .b-avatar-badge {
    font-size: calc(0.42rem);
}
.b-avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
}
.b-avatar-lg .b-avatar-text {
    font-size: calc(1.4rem);
}
.b-avatar-lg .b-avatar-badge {
    font-size: calc(0.98rem);
}
.b-avatar-group .b-avatar-group-inner {
  display: flex;
  flex-wrap: wrap;
}
.b-avatar-group .b-avatar {
  border: 1px solid #dee2e6;
}
.b-avatar-group a.b-avatar:hover:not(.disabled):not(disabled),
.b-avatar-group .btn.b-avatar:hover:not(.disabled):not(disabled) {
  z-index: 1;
}
.b-calendar {
  display: inline-flex;
}
.b-calendar .b-calendar-inner {
    min-width: 250px;
}
.b-calendar .b-calendar-header,
  .b-calendar .b-calendar-nav {
    margin-bottom: 0.25rem;
}
.b-calendar .b-calendar-nav .btn {
    padding: 0.25rem;
}
.b-calendar output {
    padding: 0.25rem;
    font-size: 80%;
}
.b-calendar output.readonly {
      background-color: #e9ecef;
      opacity: 1;
}
.b-calendar .b-calendar-footer {
    margin-top: 0.5rem;
}
.b-calendar .b-calendar-grid {
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.b-calendar .b-calendar-grid .row {
      flex-wrap: nowrap;
}
.b-calendar .b-calendar-grid-caption {
    padding: 0.25rem;
}
.b-calendar .b-calendar-grid-body .col[data-date] .btn {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 1;
    margin: 3px auto;
    padding: 9px 0;
}
.b-calendar .btn:disabled, .b-calendar .btn.disabled, .b-calendar .btn[aria-disabled="true"] {
    cursor: default;
    pointer-events: none;
}
.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-img-right {
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
.dropdown:not(.dropleft) .dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none !important;
}
.dropdown.dropleft .dropdown-toggle.dropdown-toggle-no-caret::before {
  display: none !important;
}
.dropdown .dropdown-menu:focus {
  outline: none;
}
.b-dropdown-form {
  display: inline-block;
  padding: 0 25px;
  width: 100%;
  clear: both;
  font-weight: 400;
}
.b-dropdown-form:focus {
    outline: 1px dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
}
.b-dropdown-form.disabled, .b-dropdown-form:disabled {
    outline: 0 !important;
    color: #adb5bd;
    pointer-events: none;
}
.b-dropdown-text {
  display: inline-block;
  padding: 0 25px;
  margin-bottom: 0;
  width: 100%;
  clear: both;
  font-weight: lighter;
}
.custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 1.125rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::before,
  .input-group-lg .custom-checkbox .custom-control-label::before {
    top: 0.21875rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3rem;
}
.custom-checkbox.b-custom-control-lg .custom-control-label::after,
  .input-group-lg .custom-checkbox .custom-control-label::after {
    top: 0.21875rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    background-size: 50% 50%;
}
.custom-checkbox.b-custom-control-sm,
.input-group-sm .custom-checkbox {
  font-size: 0.7875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::before,
  .input-group-sm .custom-checkbox .custom-control-label::before {
    top: 0.15312rem;
    left: -1.3125rem;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.2rem;
}
.custom-checkbox.b-custom-control-sm .custom-control-label::after,
  .input-group-sm .custom-checkbox .custom-control-label::after {
    top: 0.15312rem;
    left: -1.3125rem;
    width: 0.875rem;
    height: 0.875rem;
    background-size: 50% 50%;
}
.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-left: 2.8125rem;
}
.custom-switch.b-custom-control-lg .custom-control-label,
  .input-group-lg .custom-switch .custom-control-label {
    font-size: 1.125rem;
    line-height: 1.5;
}
.custom-switch.b-custom-control-lg .custom-control-label::before,
    .input-group-lg .custom-switch .custom-control-label::before {
      top: 0.21875rem;
      height: 1.25rem;
      left: -2.8125rem;
      width: 2.1875rem;
      border-radius: 0.625rem;
}
.custom-switch.b-custom-control-lg .custom-control-label::after,
    .input-group-lg .custom-switch .custom-control-label::after {
      top: calc( 0.21875rem + 0px);
      left: calc( -2.8125rem + 0px);
      width: calc( 1.25rem - 0px);
      height: calc( 1.25rem - 0px);
      border-radius: 0.625rem;
      background-size: 50% 50%;
}
.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
  .input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.9375rem);
}
.custom-switch.b-custom-control-sm,
.input-group-sm .custom-switch {
  padding-left: 1.96875rem;
}
.custom-switch.b-custom-control-sm .custom-control-label,
  .input-group-sm .custom-switch .custom-control-label {
    font-size: 0.7875rem;
    line-height: 1.5;
}
.custom-switch.b-custom-control-sm .custom-control-label::before,
    .input-group-sm .custom-switch .custom-control-label::before {
      top: 0.15312rem;
      left: -1.96875rem;
      width: 1.53125rem;
      height: 0.875rem;
      border-radius: 0.4375rem;
}
.custom-switch.b-custom-control-sm .custom-control-label::after,
    .input-group-sm .custom-switch .custom-control-label::after {
      top: calc( 0.15312rem + 0px);
      left: calc( -1.96875rem + 0px);
      width: calc( 0.875rem - 0px);
      height: calc( 0.875rem - 0px);
      border-radius: 0.4375rem;
      background-size: 50% 50%;
}
.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
  .input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(0.65625rem);
}
.input-group > .input-group-prepend > .btn-group > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-group > .btn,
.input-group > .input-group-append:last-child > .btn-group:not(:last-child):not(.dropdown-toggle) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group > .input-group-prepend:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.b-form-btn-label-control.form-control {
  display: flex;
  align-items: stretch;
  height: auto;
  padding: 0;
  background-image: none;
}
.input-group .b-form-btn-label-control.form-control {
    padding: 0;
}
[dir="rtl"] .b-form-btn-label-control.form-control, .b-form-btn-label-control.form-control[dir="rtl"] {
    flex-direction: row-reverse;
}
[dir="rtl"] .b-form-btn-label-control.form-control > label, .b-form-btn-label-control.form-control[dir="rtl"] > label {
      text-align: right;
}
.b-form-btn-label-control.form-control > .btn {
    line-height: 1;
    font-size: inherit;
    box-shadow: none !important;
    border: 0;
}
.b-form-btn-label-control.form-control > .btn:disabled {
      pointer-events: none;
}
.b-form-btn-label-control.form-control.is-valid > .btn {
    color: #33cc00;
}
.b-form-btn-label-control.form-control.is-invalid > .btn {
    color: #cc1417;
}
.b-form-btn-label-control.form-control > .dropdown-menu {
    padding: 0.5rem;
}
.b-form-btn-label-control.form-control > .form-control {
    height: auto;
    min-height: calc(calc(1.5em + 0.75rem + 0px) - 0px);
    padding-left: 0.25rem;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    word-break: break-word;
    font-size: inherit;
    white-space: normal;
    cursor: pointer;
}
.b-form-btn-label-control.form-control > .form-control.form-control-sm {
      min-height: calc(calc(1.5em + 0.5rem + 0px) - 0px);
}
.b-form-btn-label-control.form-control > .form-control.form-control-lg {
      min-height: calc(calc(1.5em + 1rem + 0px) - 0px);
}
.input-group.input-group-sm .b-form-btn-label-control.form-control > .form-control {
      min-height: calc(calc(1.5em + 0.5rem + 0px) - 0px);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
}
.input-group.input-group-lg .b-form-btn-label-control.form-control > .form-control {
      min-height: calc(calc(1.5em + 1rem + 0px) - 0px);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
}
.b-form-btn-label-control.form-control[aria-disabled="true"], .b-form-btn-label-control.form-control[aria-readonly="true"] {
    background-color: #e9ecef;
    opacity: 1;
}
.b-form-btn-label-control.form-control[aria-disabled="true"] {
    pointer-events: none;
}
.b-form-btn-label-control.form-control[aria-disabled="true"] > label {
      cursor: default;
}
.b-form-btn-label-control.btn-group > .dropdown-menu {
  padding: 0.5rem;
}
.custom-file-label {
  white-space: nowrap;
  overflow-x: hidden;
}
.b-custom-control-lg.custom-file,
.b-custom-control-lg .custom-file-input,
.b-custom-control-lg .custom-file-label,
.input-group-lg.custom-file,
.input-group-lg .custom-file-input,
.input-group-lg .custom-file-label {
  font-size: 1.125rem;
  height: calc(1.5em + 1rem + 0px);
}
.b-custom-control-lg .custom-file-label,
.b-custom-control-lg .custom-file-label:after,
.input-group-lg .custom-file-label,
.input-group-lg .custom-file-label:after {
  padding: 0.5rem 1rem;
  line-height: 1.5;
}
.b-custom-control-lg .custom-file-label,
.input-group-lg .custom-file-label {
  border-radius: 0.3rem;
}
.b-custom-control-lg .custom-file-label::after,
  .input-group-lg .custom-file-label::after {
    font-size: inherit;
    height: calc( 1.5em + 1rem);
    border-radius: 0 0.3rem 0.3rem 0;
}
.b-custom-control-sm.custom-file,
.b-custom-control-sm .custom-file-input,
.b-custom-control-sm .custom-file-label,
.input-group-sm.custom-file,
.input-group-sm .custom-file-input,
.input-group-sm .custom-file-label {
  font-size: 0.7875rem;
  height: calc(1.5em + 0.5rem + 0px);
}
.b-custom-control-sm .custom-file-label,
.b-custom-control-sm .custom-file-label:after,
.input-group-sm .custom-file-label,
.input-group-sm .custom-file-label:after {
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
}
.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0.2rem;
}
.b-custom-control-sm .custom-file-label::after,
  .input-group-sm .custom-file-label::after {
    font-size: inherit;
    height: calc( 1.5em + 0.5rem);
    border-radius: 0 0.2rem 0.2rem 0;
}
.was-validated .form-control:invalid,
.was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-position: right calc(0.375em + 0.1875rem) center;
}
input[type="color"].form-control {
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0.125rem 0.25rem;
}
input[type="color"].form-control.form-control-sm,
.input-group-sm input[type="color"].form-control {
  height: calc(1.5em + 0.5rem + 0px);
  padding: 0.125rem 0.25rem;
}
input[type="color"].form-control.form-control-lg,
.input-group-lg input[type="color"].form-control {
  height: calc(1.5em + 1rem + 0px);
  padding: 0.125rem 0.25rem;
}
input[type="color"].form-control:disabled {
  background-color: #adb5bd;
  opacity: 0.65;
}
.input-group > .custom-range {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-range + .form-control,
  .input-group > .custom-range + .form-control-plaintext,
  .input-group > .custom-range + .custom-select,
  .input-group > .custom-range + .custom-range,
  .input-group > .custom-range + .custom-file {
    margin-left: 0px;
}
.input-group > .form-control + .custom-range,
.input-group > .form-control-plaintext + .custom-range,
.input-group > .custom-select + .custom-range,
.input-group > .custom-range + .custom-range,
.input-group > .custom-file + .custom-range {
  margin-left: 0px;
}
.input-group > .custom-range:focus {
  z-index: 3;
}
.input-group > .custom-range:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-range:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-range {
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #c6bec2;
  height: calc(1.5em + 0.75rem + 0px);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.input-group > .custom-range {
      transition: none;
}
}
.input-group > .custom-range:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e6f7ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.input-group > .custom-range:disabled, .input-group > .custom-range[readonly] {
    background-color: #e9ecef;
}
.input-group-lg > .custom-range {
  height: calc(1.5em + 1rem + 0px);
  padding: 0 1rem;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-range {
  height: calc(1.5em + 0.5rem + 0px);
  padding: 0 0.5rem;
  border-radius: 0.2rem;
}
.was-validated .input-group .custom-range:valid, .input-group .custom-range.is-valid {
  border-color: #33cc00;
}
.was-validated .input-group .custom-range:valid:focus, .input-group .custom-range.is-valid:focus {
    border-color: #33cc00;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.was-validated .custom-range:valid:focus::-webkit-slider-thumb, .custom-range.is-valid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #9fff80;
}
.was-validated .custom-range:valid:focus::-moz-range-thumb, .custom-range.is-valid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #9fff80;
}
.was-validated .custom-range:valid:focus::-ms-thumb, .custom-range.is-valid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #9fff80;
}
.was-validated .custom-range:valid::-webkit-slider-thumb, .custom-range.is-valid::-webkit-slider-thumb {
  background-color: #33cc00;
  background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-thumb:active, .custom-range.is-valid::-webkit-slider-thumb:active {
    background-color: #9fff80;
    background-image: none;
}
.was-validated .custom-range:valid::-webkit-slider-runnable-track, .custom-range.is-valid::-webkit-slider-runnable-track {
  background-color: rgba(51, 204, 0, 0.35);
}
.was-validated .custom-range:valid::-moz-range-thumb, .custom-range.is-valid::-moz-range-thumb {
  background-color: #33cc00;
  background-image: none;
}
.was-validated .custom-range:valid::-moz-range-thumb:active, .custom-range.is-valid::-moz-range-thumb:active {
    background-color: #9fff80;
    background-image: none;
}
.was-validated .custom-range:valid::-moz-range-track, .custom-range.is-valid::-moz-range-track {
  background: rgba(51, 204, 0, 0.35);
}
.was-validated .custom-range:valid ~ .valid-feedback,
.was-validated .custom-range:valid ~ .valid-tooltip, .custom-range.is-valid ~ .valid-feedback,
.custom-range.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-range:valid::-ms-thumb, .custom-range.is-valid::-ms-thumb {
  background-color: #33cc00;
  background-image: none;
}
.was-validated .custom-range:valid::-ms-thumb:active, .custom-range.is-valid::-ms-thumb:active {
    background-color: #9fff80;
    background-image: none;
}
.was-validated .custom-range:valid::-ms-track-lower, .custom-range.is-valid::-ms-track-lower {
  background: rgba(51, 204, 0, 0.35);
}
.was-validated .custom-range:valid::-ms-track-upper, .custom-range.is-valid::-ms-track-upper {
  background: rgba(51, 204, 0, 0.35);
}
.was-validated .input-group .custom-range:invalid, .input-group .custom-range.is-invalid {
  border-color: #cc1417;
}
.was-validated .input-group .custom-range:invalid:focus, .input-group .custom-range.is-invalid:focus {
    border-color: #cc1417;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.was-validated .custom-range:invalid:focus::-webkit-slider-thumb, .custom-range.is-invalid:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #f59d9f;
}
.was-validated .custom-range:invalid:focus::-moz-range-thumb, .custom-range.is-invalid:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #f59d9f;
}
.was-validated .custom-range:invalid:focus::-ms-thumb, .custom-range.is-invalid:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f5f1f5, 0 0 0 0.2rem #f59d9f;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb, .custom-range.is-invalid::-webkit-slider-thumb {
  background-color: #cc1417;
  background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-thumb:active, .custom-range.is-invalid::-webkit-slider-thumb:active {
    background-color: #f59d9f;
    background-image: none;
}
.was-validated .custom-range:invalid::-webkit-slider-runnable-track, .custom-range.is-invalid::-webkit-slider-runnable-track {
  background-color: rgba(204, 20, 23, 0.35);
}
.was-validated .custom-range:invalid::-moz-range-thumb, .custom-range.is-invalid::-moz-range-thumb {
  background-color: #cc1417;
  background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-thumb:active, .custom-range.is-invalid::-moz-range-thumb:active {
    background-color: #f59d9f;
    background-image: none;
}
.was-validated .custom-range:invalid::-moz-range-track, .custom-range.is-invalid::-moz-range-track {
  background: rgba(204, 20, 23, 0.35);
}
.was-validated .custom-range:invalid ~ .invalid-feedback,
.was-validated .custom-range:invalid ~ .invalid-tooltip, .custom-range.is-invalid ~ .invalid-feedback,
.custom-range.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-range:invalid::-ms-thumb, .custom-range.is-invalid::-ms-thumb {
  background-color: #cc1417;
  background-image: none;
}
.was-validated .custom-range:invalid::-ms-thumb:active, .custom-range.is-invalid::-ms-thumb:active {
    background-color: #f59d9f;
    background-image: none;
}
.was-validated .custom-range:invalid::-ms-track-lower, .custom-range.is-invalid::-ms-track-lower {
  background: rgba(204, 20, 23, 0.35);
}
.was-validated .custom-range:invalid::-ms-track-upper, .custom-range.is-invalid::-ms-track-upper {
  background: rgba(204, 20, 23, 0.35);
}
.custom-radio.b-custom-control-lg,
.input-group-lg .custom-radio {
  font-size: 1.125rem;
  line-height: 1.5;
  padding-left: 1.875rem;
}
.custom-radio.b-custom-control-lg .custom-control-label::before,
  .input-group-lg .custom-radio .custom-control-label::before {
    top: 0.21875rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
}
.custom-radio.b-custom-control-lg .custom-control-label::after,
  .input-group-lg .custom-radio .custom-control-label::after {
    top: 0.21875rem;
    left: -1.875rem;
    width: 1.25rem;
    height: 1.25rem;
    background: no-repeat 50% / 50% 50%;
}
.custom-radio.b-custom-control-sm,
.input-group-sm .custom-radio {
  font-size: 0.7875rem;
  line-height: 1.5;
  padding-left: 1.3125rem;
}
.custom-radio.b-custom-control-sm .custom-control-label::before,
  .input-group-sm .custom-radio .custom-control-label::before {
    top: 0.15312rem;
    left: -1.3125rem;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
}
.custom-radio.b-custom-control-sm .custom-control-label::after,
  .input-group-sm .custom-radio .custom-control-label::after {
    top: 0.15312rem;
    left: -1.3125rem;
    width: 0.875rem;
    height: 0.875rem;
    background: no-repeat 50% / 50% 50%;
}
.b-rating {
  text-align: center;
}
.b-rating.d-inline-flex {
    width: auto;
}
.b-rating .b-rating-star,
  .b-rating .b-rating-value {
    padding: 0 0.25em;
}
.b-rating .b-rating-value {
    min-width: 2.5em;
}
.b-rating .b-rating-star {
    display: inline-flex;
    justify-content: center;
    outline: 0;
}
.b-rating .b-rating-star .b-rating-icon {
      display: inline-flex;
      transition: all 0.15s ease-in-out;
}
.b-rating.disabled, .b-rating:disabled {
    background-color: #e9ecef;
    color: #6c757d;
}
.b-rating:not(.disabled):not(.readonly) .b-rating-star {
    cursor: pointer;
}
.b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon,
  .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
    transform: scale(1.5);
}
.b-rating[dir="rtl"] .b-rating-star-half {
    transform: scale(-1, 1);
}
.b-form-spinbutton {
  text-align: center;
  overflow: hidden;
  background-image: none;
  padding: 0;
}
[dir="rtl"] .b-form-spinbutton:not(.flex-column), .b-form-spinbutton[dir="rtl"]:not(.flex-column) {
    flex-direction: row-reverse;
}
.b-form-spinbutton output {
    font-size: inherit;
    outline: 0;
    border: 0;
    background-color: transparent;
    width: auto;
    margin: 0;
    padding: 0 0.25rem;
}
.b-form-spinbutton output > div,
    .b-form-spinbutton output > bdi {
      display: block;
      min-width: 2.25em;
      height: 1.5em;
}
.b-form-spinbutton.flex-column {
    height: auto;
    width: auto;
}
.b-form-spinbutton.flex-column output {
      margin: 0 0.25rem;
      padding: 0.25rem 0;
}
.b-form-spinbutton:not(.d-inline-flex):not(.flex-column) {
    output-width: 100%;
}
.b-form-spinbutton.d-inline-flex:not(.flex-column) {
    width: auto;
}
.b-form-spinbutton .btn {
    line-height: 1;
    box-shadow: none !important;
}
.b-form-spinbutton .btn:disabled {
      pointer-events: none;
}
.b-form-spinbutton .btn:hover:not(:disabled) > div > .b-icon {
      transform: scale(1.25);
}
.b-form-spinbutton.disabled, .b-form-spinbutton.readonly {
    background-color: #e9ecef;
}
.b-form-spinbutton.disabled {
    pointer-events: none;
}
.b-form-tags .b-form-tags-list {
  margin-top: -0.25rem;
}
.b-form-tags .b-form-tags-list .b-from-tags-field,
  .b-form-tags .b-form-tags-list .b-form-tag {
    margin-top: 0.25rem;
}
.b-form-tags.focus {
  color: #495057;
  background-color: #fff;
  border-color: #e6f7ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 204, 255, 0.25);
}
.b-form-tags.focus.is-valid {
    border-color: #33cc00;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 0, 0.25);
}
.b-form-tags.focus.is-invalid {
    border-color: #cc1417;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 23, 0.25);
}
.b-form-tags.disabled {
  background-color: #e9ecef;
}
.b-form-tag {
  font-size: 75%;
  font-weight: normal;
  line-height: 1.5;
  margin-right: 0.25rem;
}
.b-form-tag.disabled {
    opacity: 0.75;
}
.b-form-tag > button.b-form-tag-remove {
    color: inherit;
    font-size: 125%;
    line-height: 1;
    float: none;
    margin-left: 0.25rem;
}
.form-control-sm .b-form-tag {
  line-height: 1.5;
}
.form-control-lg .b-form-tag {
  line-height: 1.5;
}
.media-aside {
  display: flex;
  margin-right: 1rem;
}
.media-aside-right {
  margin-right: 0;
  margin-left: 1rem;
}
.modal-backdrop {
  opacity: 0.5;
}
.b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}
.b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}
.popover.b-popover {
  display: block;
  opacity: 1;
  outline: 0;
}
.popover.b-popover.fade:not(.show) {
    opacity: 0;
}
.popover.b-popover.show {
    opacity: 1;
}
.b-popover-primary.popover {
  background-color: #e0f5ff;
  border-color: #d4f1ff;
}
.b-popover-primary.bs-popover-top > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #d4f1ff;
}
.b-popover-primary.bs-popover-top > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #e0f5ff;
}
.b-popover-primary.bs-popover-right > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #d4f1ff;
}
.b-popover-primary.bs-popover-right > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #e0f5ff;
}
.b-popover-primary.bs-popover-bottom > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #d4f1ff;
}
.b-popover-primary.bs-popover-bottom > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #d1f0ff;
}
.b-popover-primary.bs-popover-bottom .popover-header::before, .b-popover-primary.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #d1f0ff;
}
.b-popover-primary.bs-popover-left > .arrow::before, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #d4f1ff;
}
.b-popover-primary.bs-popover-left > .arrow::after, .b-popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #e0f5ff;
}
.b-popover-primary .popover-header {
  color: #212529;
  background-color: #d1f0ff;
  border-bottom-color: #b7e8ff;
}
.b-popover-primary .popover-body {
  color: #356a85;
}
.b-popover-secondary.popover {
  background-color: white;
  border-color: white;
}
.b-popover-secondary.bs-popover-top > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: white;
}
.b-popover-secondary.bs-popover-top > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: white;
}
.b-popover-secondary.bs-popover-right > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: white;
}
.b-popover-secondary.bs-popover-right > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: white;
}
.b-popover-secondary.bs-popover-bottom > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: white;
}
.b-popover-secondary.bs-popover-bottom > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #f7f7f7;
}
.b-popover-secondary.bs-popover-bottom .popover-header::before, .b-popover-secondary.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #f7f7f7;
}
.b-popover-secondary.bs-popover-left > .arrow::before, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: white;
}
.b-popover-secondary.bs-popover-left > .arrow::after, .b-popover-secondary.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: white;
}
.b-popover-secondary .popover-header {
  color: #212529;
  background-color: #f7f7f7;
  border-bottom-color: #ebebeb;
}
.b-popover-secondary .popover-body {
  color: #858585;
}
.b-popover-success.popover {
  background-color: #d6f5cc;
  border-color: #c6f1b8;
}
.b-popover-success.bs-popover-top > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #c6f1b8;
}
.b-popover-success.bs-popover-top > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #d6f5cc;
}
.b-popover-success.bs-popover-right > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #c6f1b8;
}
.b-popover-success.bs-popover-right > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #d6f5cc;
}
.b-popover-success.bs-popover-bottom > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #c6f1b8;
}
.b-popover-success.bs-popover-bottom > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ccf2bf;
}
.b-popover-success.bs-popover-bottom .popover-header::before, .b-popover-success.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #ccf2bf;
}
.b-popover-success.bs-popover-left > .arrow::before, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #c6f1b8;
}
.b-popover-success.bs-popover-left > .arrow::after, .b-popover-success.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #d6f5cc;
}
.b-popover-success .popover-header {
  color: #212529;
  background-color: #ccf2bf;
  border-bottom-color: #bbeeaa;
}
.b-popover-success .popover-body {
  color: #1b6a00;
}
.b-popover-info.popover {
  background-color: #dbf0f9;
  border-color: #cce9f7;
}
.b-popover-info.bs-popover-top > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #cce9f7;
}
.b-popover-info.bs-popover-top > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #dbf0f9;
}
.b-popover-info.bs-popover-right > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #cce9f7;
}
.b-popover-info.bs-popover-right > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #dbf0f9;
}
.b-popover-info.bs-popover-bottom > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #cce9f7;
}
.b-popover-info.bs-popover-bottom > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ceebf7;
}
.b-popover-info.bs-popover-bottom .popover-header::before, .b-popover-info.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #ceebf7;
}
.b-popover-info.bs-popover-left > .arrow::before, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #cce9f7;
}
.b-popover-info.bs-popover-left > .arrow::after, .b-popover-info.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #dbf0f9;
}
.b-popover-info .popover-header {
  color: #212529;
  background-color: #ceebf7;
  border-bottom-color: #b8e1f3;
}
.b-popover-info .popover-body {
  color: #265d76;
}
.b-popover-warning.popover {
  background-color: #fdedd7;
  border-color: #fce5c7;
}
.b-popover-warning.bs-popover-top > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #fce5c7;
}
.b-popover-warning.bs-popover-top > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #fdedd7;
}
.b-popover-warning.bs-popover-right > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #fce5c7;
}
.b-popover-warning.bs-popover-right > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fdedd7;
}
.b-popover-warning.bs-popover-bottom > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #fce5c7;
}
.b-popover-warning.bs-popover-bottom > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #fce6c8;
}
.b-popover-warning.bs-popover-bottom .popover-header::before, .b-popover-warning.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #fce6c8;
}
.b-popover-warning.bs-popover-left > .arrow::before, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #fce5c7;
}
.b-popover-warning.bs-popover-left > .arrow::after, .b-popover-warning.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fdedd7;
}
.b-popover-warning .popover-header {
  color: #212529;
  background-color: #fce6c8;
  border-bottom-color: #fbdcb0;
}
.b-popover-warning .popover-body {
  color: #80551c;
}
.b-popover-danger.popover {
  background-color: #f5d0d1;
  border-color: #f1bdbe;
}
.b-popover-danger.bs-popover-top > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #f1bdbe;
}
.b-popover-danger.bs-popover-top > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #f5d0d1;
}
.b-popover-danger.bs-popover-right > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #f1bdbe;
}
.b-popover-danger.bs-popover-right > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #f5d0d1;
}
.b-popover-danger.bs-popover-bottom > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #f1bdbe;
}
.b-popover-danger.bs-popover-bottom > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #f2c3c5;
}
.b-popover-danger.bs-popover-bottom .popover-header::before, .b-popover-danger.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #f2c3c5;
}
.b-popover-danger.bs-popover-left > .arrow::before, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #f1bdbe;
}
.b-popover-danger.bs-popover-left > .arrow::after, .b-popover-danger.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #f5d0d1;
}
.b-popover-danger .popover-header {
  color: #212529;
  background-color: #f2c3c5;
  border-bottom-color: #eeaeb0;
}
.b-popover-danger .popover-body {
  color: #6a0a0c;
}
.b-popover-light.popover {
  background-color: #fcfdfd;
  border-color: #fbfcfc;
}
.b-popover-light.bs-popover-top > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #fbfcfc;
}
.b-popover-light.bs-popover-top > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #fcfdfd;
}
.b-popover-light.bs-popover-right > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #fbfcfc;
}
.b-popover-light.bs-popover-right > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #fcfdfd;
}
.b-popover-light.bs-popover-bottom > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #fbfcfc;
}
.b-popover-light.bs-popover-bottom > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #f3f7f7;
}
.b-popover-light.bs-popover-bottom .popover-header::before, .b-popover-light.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #f3f7f7;
}
.b-popover-light.bs-popover-left > .arrow::before, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #fbfcfc;
}
.b-popover-light.bs-popover-left > .arrow::after, .b-popover-light.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #fcfdfd;
}
.b-popover-light .popover-header {
  color: #212529;
  background-color: #f3f7f7;
  border-bottom-color: #e4eded;
}
.b-popover-light .popover-body {
  color: #7c7f7f;
}
.b-popover-dark.popover {
  background-color: #d0d0d0;
  border-color: #bebebe;
}
.b-popover-dark.bs-popover-top > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #bebebe;
}
.b-popover-dark.bs-popover-top > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #d0d0d0;
}
.b-popover-dark.bs-popover-right > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #bebebe;
}
.b-popover-dark.bs-popover-right > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #d0d0d0;
}
.b-popover-dark.bs-popover-bottom > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #bebebe;
}
.b-popover-dark.bs-popover-bottom > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #c8c8c8;
}
.b-popover-dark.bs-popover-bottom .popover-header::before, .b-popover-dark.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #c8c8c8;
}
.b-popover-dark.bs-popover-left > .arrow::before, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #bebebe;
}
.b-popover-dark.bs-popover-left > .arrow::after, .b-popover-dark.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #d0d0d0;
}
.b-popover-dark .popover-header {
  color: #212529;
  background-color: #c8c8c8;
  border-bottom-color: #bcbcbc;
}
.b-popover-dark .popover-body {
  color: #0b0b0b;
}
.b-popover-pale.popover {
  background-color: #f4f4f4;
  border-color: #f0f0f0;
}
.b-popover-pale.bs-popover-top > .arrow::before, .b-popover-pale.bs-popover-auto[x-placement^="top"] > .arrow::before {
  border-top-color: #f0f0f0;
}
.b-popover-pale.bs-popover-top > .arrow::after, .b-popover-pale.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #f4f4f4;
}
.b-popover-pale.bs-popover-right > .arrow::before, .b-popover-pale.bs-popover-auto[x-placement^="right"] > .arrow::before {
  border-right-color: #f0f0f0;
}
.b-popover-pale.bs-popover-right > .arrow::after, .b-popover-pale.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #f4f4f4;
}
.b-popover-pale.bs-popover-bottom > .arrow::before, .b-popover-pale.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  border-bottom-color: #f0f0f0;
}
.b-popover-pale.bs-popover-bottom > .arrow::after, .b-popover-pale.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #ececec;
}
.b-popover-pale.bs-popover-bottom .popover-header::before, .b-popover-pale.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  border-bottom-color: #ececec;
}
.b-popover-pale.bs-popover-left > .arrow::before, .b-popover-pale.bs-popover-auto[x-placement^="left"] > .arrow::before {
  border-left-color: #f0f0f0;
}
.b-popover-pale.bs-popover-left > .arrow::after, .b-popover-pale.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #f4f4f4;
}
.b-popover-pale .popover-header {
  color: #212529;
  background-color: #ececec;
  border-bottom-color: #e0e0e0;
}
.b-popover-pale .popover-body {
  color: #686868;
}
.b-sidebar-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: calc(1030 + 5);
}
.b-sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
}
.b-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 320px;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  margin: 0;
  outline: 0;
  transform: translateX(0);
}
.b-sidebar.slide {
    transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
.b-sidebar.slide {
        transition: none;
}
}
.b-sidebar:not(.b-sidebar-right) {
    left: 0;
    right: auto;
}
.b-sidebar:not(.b-sidebar-right).slide:not(.show) {
      transform: translateX(-100%);
}
.b-sidebar:not(.b-sidebar-right) > .b-sidebar-header .close {
      margin-left: auto;
}
.b-sidebar.b-sidebar-right {
    left: auto;
    right: 0;
}
.b-sidebar.b-sidebar-right.slide:not(.show) {
      transform: translateX(100%);
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
      margin-right: auto;
}
.b-sidebar > .b-sidebar-header {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    align-items: center;
}
[dir="rtl"] .b-sidebar > .b-sidebar-header {
      flex-direction: row-reverse;
}
.b-sidebar > .b-sidebar-header .close {
      float: none;
      font-size: 1.5rem;
}
.b-sidebar > .b-sidebar-body {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
}
.b-sidebar > .b-sidebar-footer {
    flex-grow: 0;
}
.b-skeleton-wrapper {
  cursor: wait;
}
.b-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  -webkit-mask-image: radial-gradient(white, black);
          mask-image: radial-gradient(white, black);
}
.b-skeleton::before {
    content: "\00a0";
}
.b-skeleton-text {
  height: 0.9rem;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}
.b-skeleton-button {
  width: 75px;
  padding: 1.1rem 3.9rem;
  font-size: 0.9rem;
  line-height: 1.25;
  border-radius: 1.875rem;
}
.b-skeleton-avatar {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}
.b-skeleton-input {
  height: calc(1.5em + 0.75rem + 0px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: #c6bec2 solid 0px;
  border-radius: 0.25rem;
}
.b-skeleton-icon-wrapper svg {
  color: rgba(0, 0, 0, 0.12);
}
.b-skeleton-img {
  height: 100%;
  width: 100%;
}
.b-skeleton-animate-wave::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  -webkit-animation: b-skeleton-animate-wave 1.75s linear infinite;
          animation: b-skeleton-animate-wave 1.75s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
.b-skeleton-animate-wave::after {
      background: none;
      -webkit-animation: none;
              animation: none;
}
}
@-webkit-keyframes b-skeleton-animate-wave {
from {
    transform: translateX(-100%);
}
to {
    transform: translateX(100%);
}
}
@keyframes b-skeleton-animate-wave {
from {
    transform: translateX(-100%);
}
to {
    transform: translateX(100%);
}
}
.b-skeleton-animate-fade {
  -webkit-animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
          animation: b-skeleton-animate-fade 0.875s ease-in-out alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
.b-skeleton-animate-fade {
      -webkit-animation: none;
              animation: none;
}
}
@-webkit-keyframes b-skeleton-animate-fade {
0% {
    opacity: 1;
}
100% {
    opacity: 0.4;
}
}
@keyframes b-skeleton-animate-fade {
0% {
    opacity: 1;
}
100% {
    opacity: 0.4;
}
}
.b-skeleton-animate-throb {
  -webkit-animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
          animation: b-skeleton-animate-throb 0.875s ease-in alternate infinite;
}
@media (prefers-reduced-motion: reduce) {
.b-skeleton-animate-throb {
      -webkit-animation: none;
              animation: none;
}
}
@-webkit-keyframes b-skeleton-animate-throb {
0% {
    transform: scale(1);
}
100% {
    transform: scale(0.975);
}
}
@keyframes b-skeleton-animate-throb {
0% {
    transform: scale(1);
}
100% {
    transform: scale(0.975);
}
}
.table.b-table.b-table-fixed {
  table-layout: fixed;
}
.table.b-table.b-table-no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}
.table.b-table[aria-busy="true"] {
  opacity: 0.55;
}
.table.b-table > tbody > tr.b-table-details > td {
  border-top: none !important;
}
.table.b-table > caption {
  caption-side: bottom;
}
.table.b-table.b-table-caption-top > caption {
  caption-side: top !important;
}
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table.b-table.table-hover > tbody > tr.table-active:hover td,
.table.b-table.table-hover > tbody > tr.table-active:hover th {
  color: #34495e;
  background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
  background-repeat: no-repeat;
}
.table.b-table > tbody > .bg-active,
.table.b-table > tbody > .bg-active > th,
.table.b-table > tbody > .bg-active > td {
  background-color: rgba(255, 255, 255, 0.075) !important;
}
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover td,
.table.b-table.table-hover.table-dark > tbody > tr.bg-active:hover th {
  color: #fff;
  background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
  background-repeat: no-repeat;
}
.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 1rem;
}
.b-table-sticky-header > .table,
  .table-responsive > .table,
  [class*="table-responsive-"] > .table {
    margin-bottom: 0;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}
@media print {
.b-table-sticky-header {
    overflow-y: visible !important;
    max-height: none !important;
}
}
@supports (position: sticky) {
.b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 2;
}
.b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    position: sticky;
    left: 0;
}
.b-table-sticky-header > .table.b-table > thead > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > thead > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > thead > tr > .b-table-sticky-column {
    z-index: 5;
}
.b-table-sticky-header > .table.b-table > tbody > tr > .b-table-sticky-column,
  .b-table-sticky-header > .table.b-table > tfoot > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column,
  .table-responsive > .table.b-table > tfoot > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tbody > tr > .b-table-sticky-column,
  [class*="table-responsive-"] > .table.b-table > tfoot > tr > .b-table-sticky-column {
    z-index: 2;
}
.table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    color: #34495e;
    background-color: #f5f1f5;
}
.table.b-table.table-dark > thead > tr > .bg-b-table-default,
  .table.b-table.table-dark > tbody > tr > .bg-b-table-default,
  .table.b-table.table-dark > tfoot > tr > .bg-b-table-default {
    color: #fff;
    background-color: #343a40;
}
.table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background-image: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    background-repeat: no-repeat;
}
.table.b-table.table-striped.table-dark > tbody > tr:nth-of-type(odd) > .bg-b-table-default {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    background-repeat: no-repeat;
}
.table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    color: #34495e;
    background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
    background-repeat: no-repeat;
}
.table.b-table.table-hover.table-dark > tbody > tr:hover > .bg-b-table-default {
    color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.075));
    background-repeat: no-repeat;
}
}
.table.b-table > thead > tr > [aria-sort],
.table.b-table > tfoot > tr > [aria-sort] {
  cursor: pointer;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(0.75rem / 2) center;
    padding-right: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(0.75rem / 2) center;
    padding-left: calc(0.75rem + 0.65em);
}
.table.b-table > thead > tr > [aria-sort="none"],
.table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort="ascending"],
.table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > [aria-sort="descending"],
.table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort="none"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="none"],
.table.b-table > .thead-dark > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort="ascending"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="ascending"],
.table.b-table > .thead-dark > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-dark > thead > tr > [aria-sort="descending"],
.table.b-table.table-dark > tfoot > tr > [aria-sort="descending"],
.table.b-table > .thead-dark > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort="none"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort="ascending"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table > thead > tr > .table-dark[aria-sort="descending"],
.table.b-table > tfoot > tr > .table-dark[aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(0.3rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(0.3rem / 2) center;
  padding-left: calc(0.3rem + 0.65em);
}
.table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  cursor: pointer;
}
.table.b-table.b-table-selectable:not(.b-table-selectable-no-click).b-table-selecting.b-table-select-range > tbody > tr {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@media (max-width: 575.98px) {
.table.b-table.b-table-stacked-sm {
    display: block;
    width: 100%;
}
.table.b-table.b-table-stacked-sm > caption,
    .table.b-table.b-table-stacked-sm > tbody,
    .table.b-table.b-table-stacked-sm > tbody > tr,
    .table.b-table.b-table-stacked-sm > tbody > tr > td,
    .table.b-table.b-table-stacked-sm > tbody > tr > th {
      display: block;
}
.table.b-table.b-table-stacked-sm > thead,
    .table.b-table.b-table-stacked-sm > tfoot {
      display: none;
}
.table.b-table.b-table-stacked-sm > thead > tr.b-table-top-row,
      .table.b-table.b-table-stacked-sm > thead > tr.b-table-bottom-row,
      .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-top-row,
      .table.b-table.b-table-stacked-sm > tfoot > tr.b-table-bottom-row {
        display: none;
}
.table.b-table.b-table-stacked-sm > caption {
      caption-side: top !important;
}
.table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: bold;
      font-style: normal;
      padding: 0 calc(1rem / 2) 0 0;
      margin: 0;
}
.table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::after {
      display: block;
      clear: both;
      content: "";
}
.table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
      display: inline-block;
      width: calc(100% - 40%);
      padding: 0 0 0 calc(1rem / 2);
      margin: 0;
}
.table.b-table.b-table-stacked-sm > tbody > tr.top-row, .table.b-table.b-table-stacked-sm > tbody > tr.bottom-row {
      display: none;
}
.table.b-table.b-table-stacked-sm > tbody > tr > :first-child {
      border-top-width: 0;
}
.table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + td,
    .table.b-table.b-table-stacked-sm > tbody > tr > [rowspan] + th {
      border-top-width: 0;
}
}
@media (max-width: 767.98px) {
.table.b-table.b-table-stacked-md {
    display: block;
    width: 100%;
}
.table.b-table.b-table-stacked-md > caption,
    .table.b-table.b-table-stacked-md > tbody,
    .table.b-table.b-table-stacked-md > tbody > tr,
    .table.b-table.b-table-stacked-md > tbody > tr > td,
    .table.b-table.b-table-stacked-md > tbody > tr > th {
      display: block;
}
.table.b-table.b-table-stacked-md > thead,
    .table.b-table.b-table-stacked-md > tfoot {
      display: none;
}
.table.b-table.b-table-stacked-md > thead > tr.b-table-top-row,
      .table.b-table.b-table-stacked-md > thead > tr.b-table-bottom-row,
      .table.b-table.b-table-stacked-md > tfoot > tr.b-table-top-row,
      .table.b-table.b-table-stacked-md > tfoot > tr.b-table-bottom-row {
        display: none;
}
.table.b-table.b-table-stacked-md > caption {
      caption-side: top !important;
}
.table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: bold;
      font-style: normal;
      padding: 0 calc(1rem / 2) 0 0;
      margin: 0;
}
.table.b-table.b-table-stacked-md > tbody > tr > [data-label]::after {
      display: block;
      clear: both;
      content: "";
}
.table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
      display: inline-block;
      width: calc(100% - 40%);
      padding: 0 0 0 calc(1rem / 2);
      margin: 0;
}
.table.b-table.b-table-stacked-md > tbody > tr.top-row, .table.b-table.b-table-stacked-md > tbody > tr.bottom-row {
      display: none;
}
.table.b-table.b-table-stacked-md > tbody > tr > :first-child {
      border-top-width: 0;
}
.table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + td,
    .table.b-table.b-table-stacked-md > tbody > tr > [rowspan] + th {
      border-top-width: 0;
}
}
@media (max-width: 991.98px) {
.table.b-table.b-table-stacked-lg {
    display: block;
    width: 100%;
}
.table.b-table.b-table-stacked-lg > caption,
    .table.b-table.b-table-stacked-lg > tbody,
    .table.b-table.b-table-stacked-lg > tbody > tr,
    .table.b-table.b-table-stacked-lg > tbody > tr > td,
    .table.b-table.b-table-stacked-lg > tbody > tr > th {
      display: block;
}
.table.b-table.b-table-stacked-lg > thead,
    .table.b-table.b-table-stacked-lg > tfoot {
      display: none;
}
.table.b-table.b-table-stacked-lg > thead > tr.b-table-top-row,
      .table.b-table.b-table-stacked-lg > thead > tr.b-table-bottom-row,
      .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-top-row,
      .table.b-table.b-table-stacked-lg > tfoot > tr.b-table-bottom-row {
        display: none;
}
.table.b-table.b-table-stacked-lg > caption {
      caption-side: top !important;
}
.table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: bold;
      font-style: normal;
      padding: 0 calc(1rem / 2) 0 0;
      margin: 0;
}
.table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::after {
      display: block;
      clear: both;
      content: "";
}
.table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
      display: inline-block;
      width: calc(100% - 40%);
      padding: 0 0 0 calc(1rem / 2);
      margin: 0;
}
.table.b-table.b-table-stacked-lg > tbody > tr.top-row, .table.b-table.b-table-stacked-lg > tbody > tr.bottom-row {
      display: none;
}
.table.b-table.b-table-stacked-lg > tbody > tr > :first-child {
      border-top-width: 0;
}
.table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + td,
    .table.b-table.b-table-stacked-lg > tbody > tr > [rowspan] + th {
      border-top-width: 0;
}
}
@media (max-width: 1199.98px) {
.table.b-table.b-table-stacked-xl {
    display: block;
    width: 100%;
}
.table.b-table.b-table-stacked-xl > caption,
    .table.b-table.b-table-stacked-xl > tbody,
    .table.b-table.b-table-stacked-xl > tbody > tr,
    .table.b-table.b-table-stacked-xl > tbody > tr > td,
    .table.b-table.b-table-stacked-xl > tbody > tr > th {
      display: block;
}
.table.b-table.b-table-stacked-xl > thead,
    .table.b-table.b-table-stacked-xl > tfoot {
      display: none;
}
.table.b-table.b-table-stacked-xl > thead > tr.b-table-top-row,
      .table.b-table.b-table-stacked-xl > thead > tr.b-table-bottom-row,
      .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-top-row,
      .table.b-table.b-table-stacked-xl > tfoot > tr.b-table-bottom-row {
        display: none;
}
.table.b-table.b-table-stacked-xl > caption {
      caption-side: top !important;
}
.table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::before {
      content: attr(data-label);
      width: 40%;
      float: left;
      text-align: right;
      overflow-wrap: break-word;
      font-weight: bold;
      font-style: normal;
      padding: 0 calc(1rem / 2) 0 0;
      margin: 0;
}
.table.b-table.b-table-stacked-xl > tbody > tr > [data-label]::after {
      display: block;
      clear: both;
      content: "";
}
.table.b-table.b-table-stacked-xl > tbody > tr > [data-label] > div {
      display: inline-block;
      width: calc(100% - 40%);
      padding: 0 0 0 calc(1rem / 2);
      margin: 0;
}
.table.b-table.b-table-stacked-xl > tbody > tr.top-row, .table.b-table.b-table-stacked-xl > tbody > tr.bottom-row {
      display: none;
}
.table.b-table.b-table-stacked-xl > tbody > tr > :first-child {
      border-top-width: 0;
}
.table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + td,
    .table.b-table.b-table-stacked-xl > tbody > tr > [rowspan] + th {
      border-top-width: 0;
}
}
.table.b-table.b-table-stacked {
  display: block;
  width: 100%;
}
.table.b-table.b-table-stacked > caption,
  .table.b-table.b-table-stacked > tbody,
  .table.b-table.b-table-stacked > tbody > tr,
  .table.b-table.b-table-stacked > tbody > tr > td,
  .table.b-table.b-table-stacked > tbody > tr > th {
    display: block;
}
.table.b-table.b-table-stacked > thead,
  .table.b-table.b-table-stacked > tfoot {
    display: none;
}
.table.b-table.b-table-stacked > thead > tr.b-table-top-row,
    .table.b-table.b-table-stacked > thead > tr.b-table-bottom-row,
    .table.b-table.b-table-stacked > tfoot > tr.b-table-top-row,
    .table.b-table.b-table-stacked > tfoot > tr.b-table-bottom-row {
      display: none;
}
.table.b-table.b-table-stacked > caption {
    caption-side: top !important;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: right;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::after {
    display: block;
    clear: both;
    content: "";
}
.table.b-table.b-table-stacked > tbody > tr > [data-label] > div {
    display: inline-block;
    width: calc(100% - 40%);
    padding: 0 0 0 calc(1rem / 2);
    margin: 0;
}
.table.b-table.b-table-stacked > tbody > tr.top-row, .table.b-table.b-table-stacked > tbody > tr.bottom-row {
    display: none;
}
.table.b-table.b-table-stacked > tbody > tr > :first-child {
    border-top-width: 0;
}
.table.b-table.b-table-stacked > tbody > tr > [rowspan] + td,
  .table.b-table.b-table-stacked > tbody > tr > [rowspan] + th {
    border-top-width: 0;
}
.b-time {
  min-width: 150px;
}
.b-time[aria-disabled="true"] output,
  .b-time[aria-readonly="true"] output,
  .b-time output.disabled {
    background-color: #e9ecef;
    opacity: 1;
}
.b-time[aria-disabled="true"] output {
    pointer-events: none;
}
[dir="rtl"] .b-time > .d-flex:not(.flex-column) {
    flex-direction: row-reverse;
}
.b-time .b-time-header {
    margin-bottom: 0.5rem;
}
.b-time .b-time-header output {
      padding: 0.25rem;
      font-size: 80%;
}
.b-time .b-time-footer {
    margin-top: 0.5rem;
}
.b-time .b-time-ampm {
    margin-left: 0.5rem;
}
.b-toast {
  display: block;
  position: relative;
  max-width: 350px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-clip: padding-box;
  z-index: 1;
  border-radius: 0.45rem;
}
.b-toast .toast {
    background-color: rgba(255, 255, 255, 0.85);
}
.b-toast:not(:last-child) {
    margin-bottom: 1.15rem;
}
.b-toast.b-toast-solid .toast {
    background-color: white;
}
.b-toast .toast {
    opacity: 1;
}
.b-toast .toast.fade:not(.show) {
      opacity: 0;
}
.b-toast .toast .toast-body {
      display: block;
}
.b-toast-primary .toast {
  background-color: rgba(250, 253, 255, 0.85);
  border-color: rgba(212, 241, 255, 0.85);
  color: #356a85;
}
.b-toast-primary .toast .toast-header {
    color: #356a85;
    background-color: rgba(224, 245, 255, 0.85);
    border-bottom-color: rgba(212, 241, 255, 0.85);
}
.b-toast-primary.b-toast-solid .toast {
  background-color: #fafdff;
}
.b-toast-secondary .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.85);
  color: #858585;
}
.b-toast-secondary .toast .toast-header {
    color: #858585;
    background-color: rgba(255, 255, 255, 0.85);
    border-bottom-color: rgba(255, 255, 255, 0.85);
}
.b-toast-secondary.b-toast-solid .toast {
  background-color: white;
}
.b-toast-success .toast {
  background-color: rgba(231, 249, 225, 0.85);
  border-color: rgba(198, 241, 184, 0.85);
  color: #1b6a00;
}
.b-toast-success .toast .toast-header {
    color: #1b6a00;
    background-color: rgba(214, 245, 204, 0.85);
    border-bottom-color: rgba(198, 241, 184, 0.85);
}
.b-toast-success.b-toast-solid .toast {
  background-color: #e7f9e1;
}
.b-toast-info .toast {
  background-color: rgba(241, 249, 253, 0.85);
  border-color: rgba(204, 233, 247, 0.85);
  color: #265d76;
}
.b-toast-info .toast .toast-header {
    color: #265d76;
    background-color: rgba(219, 240, 249, 0.85);
    border-bottom-color: rgba(204, 233, 247, 0.85);
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f1f9fd;
}
.b-toast-warning .toast {
  background-color: rgba(254, 248, 239, 0.85);
  border-color: rgba(252, 229, 199, 0.85);
  color: #80551c;
}
.b-toast-warning .toast .toast-header {
    color: #80551c;
    background-color: rgba(253, 237, 215, 0.85);
    border-bottom-color: rgba(252, 229, 199, 0.85);
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fef8ef;
}
.b-toast-danger .toast {
  background-color: rgba(249, 229, 230, 0.85);
  border-color: rgba(241, 189, 190, 0.85);
  color: #6a0a0c;
}
.b-toast-danger .toast .toast-header {
    color: #6a0a0c;
    background-color: rgba(245, 208, 209, 0.85);
    border-bottom-color: rgba(241, 189, 190, 0.85);
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #f9e5e6;
}
.b-toast-light .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(251, 252, 252, 0.85);
  color: #7c7f7f;
}
.b-toast-light .toast .toast-header {
    color: #7c7f7f;
    background-color: rgba(252, 253, 253, 0.85);
    border-bottom-color: rgba(251, 252, 252, 0.85);
}
.b-toast-light.b-toast-solid .toast {
  background-color: white;
}
.b-toast-dark .toast {
  background-color: rgba(221, 221, 221, 0.85);
  border-color: rgba(190, 190, 190, 0.85);
  color: #0b0b0b;
}
.b-toast-dark .toast .toast-header {
    color: #0b0b0b;
    background-color: rgba(208, 208, 208, 0.85);
    border-bottom-color: rgba(190, 190, 190, 0.85);
}
.b-toast-dark.b-toast-solid .toast {
  background-color: #dddddd;
}
.b-toast-pale .toast {
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(240, 240, 240, 0.85);
  color: #686868;
}
.b-toast-pale .toast .toast-header {
    color: #686868;
    background-color: rgba(244, 244, 244, 0.85);
    border-bottom-color: rgba(240, 240, 240, 0.85);
}
.b-toast-pale.b-toast-solid .toast {
  background-color: white;
}
.b-toaster {
  z-index: 1100;
}
.b-toaster .b-toaster-slot {
    position: relative;
    display: block;
}
.b-toaster .b-toaster-slot:empty {
      display: none !important;
}
.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full, .b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  position: fixed;
  left: 0.5rem;
  right: 0.5rem;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
    position: absolute;
    max-width: 350px;
    width: 100%;
    /* IE 11 fix */
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
}
.b-toaster.b-toaster-top-full .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
  width: 100%;
  max-width: 100%;
}
.b-toaster.b-toaster-top-full .b-toaster-slot .b-toast,
  .b-toaster.b-toaster-top-full .b-toaster-slot .toast, .b-toaster.b-toaster-bottom-full .b-toaster-slot .b-toast,
  .b-toaster.b-toaster-bottom-full .b-toaster-slot .toast {
    width: 100%;
    max-width: 100%;
}
.b-toaster.b-toaster-top-right, .b-toaster.b-toaster-top-left, .b-toaster.b-toaster-top-center, .b-toaster.b-toaster-top-full {
  top: 0;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
    top: 0.5rem;
}
.b-toaster.b-toaster-bottom-right, .b-toaster.b-toaster-bottom-left, .b-toaster.b-toaster-bottom-center, .b-toaster.b-toaster-bottom-full {
  bottom: 0;
}
.b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot, .b-toaster.b-toaster-bottom-full .b-toaster-slot {
    bottom: 0.5rem;
}
.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-bottom-right .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-left: auto;
}
.b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-bottom-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-bottom-center .b-toaster-slot {
  margin-right: auto;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-right .b-toast.b-toaster-move, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-move, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-move {
  transition: transform 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-enter-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-to .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-enter-active .toast.fade {
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active {
  position: absolute;
  transition-delay: 0.175s;
}
.b-toaster.b-toaster-top-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-top-left .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-right .b-toast.b-toaster-leave-active .toast.fade, .b-toaster.b-toaster-bottom-left .b-toast.b-toaster-leave-active .toast.fade {
    transition-delay: 0s;
}
.tooltip.b-tooltip {
  display: block;
  opacity: 0.9;
  outline: 0;
}
.tooltip.b-tooltip.fade:not(.show) {
    opacity: 0;
}
.tooltip.b-tooltip.show {
    opacity: 0.9;
}
.tooltip.b-tooltip.noninteractive {
    pointer-events: none;
}
.tooltip.b-tooltip .arrow {
    margin: 0 0.45rem;
}
.tooltip.b-tooltip.bs-tooltip-right .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^="right"] .arrow, .tooltip.b-tooltip.bs-tooltip-left .arrow, .tooltip.b-tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
    margin: 0.45rem 0;
}
.tooltip.b-tooltip-primary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #66ccff;
}
.tooltip.b-tooltip-primary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #66ccff;
}
.tooltip.b-tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #66ccff;
}
.tooltip.b-tooltip-primary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #66ccff;
}
.tooltip.b-tooltip-primary .tooltip-inner {
  color: #212529;
  background-color: #66ccff;
}
.tooltip.b-tooltip-secondary.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: white;
}
.tooltip.b-tooltip-secondary.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: white;
}
.tooltip.b-tooltip-secondary.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white;
}
.tooltip.b-tooltip-secondary.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: white;
}
.tooltip.b-tooltip-secondary .tooltip-inner {
  color: #212529;
  background-color: white;
}
.tooltip.b-tooltip-success.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #33cc00;
}
.tooltip.b-tooltip-success.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #33cc00;
}
.tooltip.b-tooltip-success.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #33cc00;
}
.tooltip.b-tooltip-success.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #33cc00;
}
.tooltip.b-tooltip-success .tooltip-inner {
  color: #fff;
  background-color: #33cc00;
}
.tooltip.b-tooltip-info.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #4ab2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #4ab2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #4ab2e3;
}
.tooltip.b-tooltip-info.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #4ab2e3;
}
.tooltip.b-tooltip-info .tooltip-inner {
  color: #212529;
  background-color: #4ab2e3;
}
.tooltip.b-tooltip-warning.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #f6a336;
}
.tooltip.b-tooltip-warning.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #f6a336;
}
.tooltip.b-tooltip-warning.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #f6a336;
}
.tooltip.b-tooltip-warning.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #f6a336;
}
.tooltip.b-tooltip-warning .tooltip-inner {
  color: #212529;
  background-color: #f6a336;
}
.tooltip.b-tooltip-danger.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #cc1417;
}
.tooltip.b-tooltip-danger.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #cc1417;
}
.tooltip.b-tooltip-danger.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #cc1417;
}
.tooltip.b-tooltip-danger.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #cc1417;
}
.tooltip.b-tooltip-danger .tooltip-inner {
  color: #fff;
  background-color: #cc1417;
}
.tooltip.b-tooltip-light.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #eff4f5;
}
.tooltip.b-tooltip-light.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #eff4f5;
}
.tooltip.b-tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #eff4f5;
}
.tooltip.b-tooltip-light.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #eff4f5;
}
.tooltip.b-tooltip-light .tooltip-inner {
  color: #212529;
  background-color: #eff4f5;
}
.tooltip.b-tooltip-dark.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #161616;
}
.tooltip.b-tooltip-dark.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #161616;
}
.tooltip.b-tooltip-dark.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #161616;
}
.tooltip.b-tooltip-dark.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #161616;
}
.tooltip.b-tooltip-dark .tooltip-inner {
  color: #fff;
  background-color: #161616;
}
.tooltip.b-tooltip-pale.bs-tooltip-top .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #c8c8c8;
}
.tooltip.b-tooltip-pale.bs-tooltip-right .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #c8c8c8;
}
.tooltip.b-tooltip-pale.bs-tooltip-bottom .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #c8c8c8;
}
.tooltip.b-tooltip-pale.bs-tooltip-left .arrow::before, .tooltip.b-tooltip-pale.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #c8c8c8;
}
.tooltip.b-tooltip-pale .tooltip-inner {
  color: #212529;
  background-color: #c8c8c8;
}
.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon,
.b-icon.b-iconstack .b-icon-animation-cylon > g {
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
          animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-cylon,
    .b-icon.b-iconstack .b-icon-animation-cylon > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-cylon-vertical,
.b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
          animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-cylon-vertical,
    .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-fade,
.b-icon.b-iconstack .b-icon-animation-fade > g {
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
          animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-fade,
    .b-icon.b-iconstack .b-icon-animation-fade > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-spin,
.b-icon.b-iconstack .b-icon-animation-spin > g {
  transform-origin: center;
  -webkit-animation: 2s infinite linear normal b-icon-animation-spin;
          animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-spin,
    .b-icon.b-iconstack .b-icon-animation-spin > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-spin-reverse,
.b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-spin-reverse,
    .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-spin-pulse,
.b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  transform-origin: center;
  -webkit-animation: 1s infinite steps(8) normal b-icon-animation-spin;
          animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-spin-pulse,
    .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-spin-reverse-pulse,
.b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-spin-reverse-pulse,
    .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
      -webkit-animation: none;
              animation: none;
}
}
.b-icon.b-icon-animation-throb,
.b-icon.b-iconstack .b-icon-animation-throb > g {
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
          animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
.b-icon.b-icon-animation-throb,
    .b-icon.b-iconstack .b-icon-animation-throb > g {
      -webkit-animation: none;
              animation: none;
}
}
@-webkit-keyframes b-icon-animation-cylon {
0% {
    transform: translateX(-25%);
}
100% {
    transform: translateX(25%);
}
}
@keyframes b-icon-animation-cylon {
0% {
    transform: translateX(-25%);
}
100% {
    transform: translateX(25%);
}
}
@-webkit-keyframes b-icon-animation-cylon-vertical {
0% {
    transform: translateY(25%);
}
100% {
    transform: translateY(-25%);
}
}
@keyframes b-icon-animation-cylon-vertical {
0% {
    transform: translateY(25%);
}
100% {
    transform: translateY(-25%);
}
}
@-webkit-keyframes b-icon-animation-fade {
0% {
    opacity: 0.1;
}
100% {
    opacity: 1;
}
}
@keyframes b-icon-animation-fade {
0% {
    opacity: 0.1;
}
100% {
    opacity: 1;
}
}
@-webkit-keyframes b-icon-animation-spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(359deg);
}
}
@keyframes b-icon-animation-spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(359deg);
}
}
@-webkit-keyframes b-icon-animation-throb {
0% {
    opacity: 0.5;
    transform: scale(0.5);
}
100% {
    opacity: 1;
    transform: scale(1);
}
}
@keyframes b-icon-animation-throb {
0% {
    opacity: 0.5;
    transform: scale(0.5);
}
100% {
    opacity: 1;
    transform: scale(1);
}
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}
.ion {
  display: inline-flex;
}
.ion__svg {
  fill: currentColor;
}
@-webkit-keyframes ionShake {
10%,
  90% {
    transform: translate3d(-1px, 0, 0);
}
20%,
  80% {
    transform: translate3d(2px, 0, 0);
}
30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
}
40%,
  60% {
    transform: translate3d(4px, 0, 0);
}
}
@keyframes ionShake {
10%,
  90% {
    transform: translate3d(-1px, 0, 0);
}
20%,
  80% {
    transform: translate3d(2px, 0, 0);
}
30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
}
40%,
  60% {
    transform: translate3d(4px, 0, 0);
}
}
@-webkit-keyframes ionRotate {
100% {
    transform: rotate(360deg);
}
}
@keyframes ionRotate {
100% {
    transform: rotate(360deg);
}
}
@-webkit-keyframes ionBeat {
0% {
    transform: scale(0.75);
}
20% {
    transform: scale(1);
}
40% {
    transform: scale(0.75);
}
60% {
    transform: scale(1);
}
80% {
    transform: scale(0.75);
}
100% {
    transform: scale(0.75);
}
}
@keyframes ionBeat {
0% {
    transform: scale(0.75);
}
20% {
    transform: scale(1);
}
40% {
    transform: scale(0.75);
}
60% {
    transform: scale(1);
}
80% {
    transform: scale(0.75);
}
100% {
    transform: scale(0.75);
}
}
.ion-beat {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ionBeat;
  animation-name: ionBeat;
  -webkit-animation-duration: 0.82s;
  animation-duration: 0.82s;
}
.ion-shake {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ionShake;
  animation-name: ionShake;
  -webkit-animation-duration: 0.82s;
  animation-duration: 0.82s;
}
.ion-rotate {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ionRotate;
  animation-name: ionRotate;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.spinner {
  margin-left: auto;
  margin-right: auto;
}
.spinner:after {
    background-color: #cc1417;
}
.spinner--rotate-square-2::after {
  background-color: #2e517b !important;
}
.navbar .navbar-nav > .nav-item > .nav-link {
  padding-right: 38px;
  padding-left: 38px;
  line-height: 0;
}
.navbar .navbar-nav > .nav-item {
  display: flex;
  align-items: center;
}
.help-page h1,
.help-page .h1 {
  margin-bottom: 1.2rem;
  font-size: 1.9rem;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 2px;
  padding-left: 1.2rem;
  color: #35353a;
  padding-bottom: .8rem;
  display: block;
  border-bottom: 2px solid #cc1417;
}
.help-page p {
  padding-left: .5rem;
}
.help-page h2,
.help-page .h2 {
  display: block;
  padding-left: .5rem;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  color: #606066;
}
.help-page h3,
.help-page .h3 {
  margin-top: 1.5rem;
  padding-left: .9rem;
  color: #81be56;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-variant: small-caps;
}
.help-page h4,
.help-page .h4 {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.help-page h5, .help-page .h5 {
  font-size: 0.9rem;
  margin-left: 20px;
}
.blockquote {
  padding: 0 1.625rem;
  margin: 2.5rem 0 2.5rem 1rem;
  border-left: 0.375rem solid #33cc00;
  line-height: 2rem;
  color: #919191;
  font-style: oblique;
}
.blockquote-footer::before {
  content: "--- ";
}
.btn {
  box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
  font-family: inherit;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
}
.btn i {
    letter-spacing: unset !important;
    margin: auto;
    display: inline-block;
    text-align: center;
}
.btn.dropdown-toggle.theme-toggle {
    padding: 1.1rem 3.9rem;
    font-size: 0.9rem;
    line-height: 1.25;
    border-radius: 1.875rem;
}
.btn:focus, .btn:active, .btn.focus {
    box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
}
.btn.btn-sm, .btn-group-sm > .btn {
  padding: 0.7rem 2.5rem;
  font-size: 0.9rem;
  line-height: 1.25;
  border-radius: 1.875rem;
}
.btn.btn-micro {
  padding: 0.625rem 1rem;
  font-size: 85%;
  line-height: 1.25;
  border-radius: 1.875rem;
}
.btn.btn-primary {
  background-color: #66ccff;
  color: #fff;
  box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
  border: none;
}
.btn.btn-primary:hover {
    color: #fff;
    background-color: #99ddff;
}
.btn.btn-primary.active, .btn.btn-primary:active, .btn.btn-primary:not([disabled]):not(.disabled):active, .btn.btn-primary:not([disabled]):not(.disabled).active {
    background-color: #1ab3ff;
    box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
    color: #fff;
}
.btn.btn-primary.active:focus, .btn.btn-primary:active:focus, .btn.btn-primary:not([disabled]):not(.disabled):active:focus, .btn.btn-primary:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
}
.btn.btn-primary[disabled], .btn.btn-primary[disabled]:hover {
    background-color: #1ab3ff;
    color: #0088cc;
    opacity: 0.5;
}
.btn.btn-primary.hover {
    background-color: #99ddff !important;
    color: #fff;
}
.btn.btn-primary.focus {
    background-color: #1ab3ff;
    color: #fff;
}
.btn.btn-secondary {
  background-color: #66ccff;
  color: #fff;
  box-shadow: none;
  border: 2px solid #66ccff;
  color: #66ccff;
  border: 2px solid #66ccff;
  background-color: transparent;
}
.btn.btn-secondary:hover {
    color: #fff;
    background-color: #99ddff;
}
.btn.btn-secondary.active, .btn.btn-secondary:active, .btn.btn-secondary:not([disabled]):not(.disabled):active, .btn.btn-secondary:not([disabled]):not(.disabled).active {
    background-color: #1ab3ff;
    box-shadow: none;
    color: #fff;
}
.btn.btn-secondary.active:focus, .btn.btn-secondary:active:focus, .btn.btn-secondary:not([disabled]):not(.disabled):active:focus, .btn.btn-secondary:not([disabled]):not(.disabled).active:focus {
      box-shadow: none;
}
.btn.btn-secondary[disabled], .btn.btn-secondary[disabled]:hover {
    background-color: #1ab3ff;
    color: #0088cc;
    opacity: 0.5;
}
.btn.btn-secondary[disabled], .btn.btn-secondary[disabled]:hover {
    background-color: #fff;
    color: #66ccff;
    border: 2px solid #66ccff;
}
.btn.btn-secondary:hover {
    border: 2px solid #99ddff;
}
.btn.btn-secondary.hover {
    border: 2px solid #99ddff !important;
    background-color: #99ddff !important;
    color: #fff;
}
.btn.btn-secondary:active, .btn.btn-secondary:focus, .btn.btn-secondary.focus {
    border: 2px solid #1ab3ff !important;
    background-color: #1ab3ff !important;
    color: #fff;
}
.btn.btn-danger {
  background-color: #cc1417;
  color: #fff;
  box-shadow: 0 4px 9.6px 0.4px rgba(204, 20, 23, 0.5);
  border: none;
}
.btn.btn-danger:hover {
    color: #fff;
    background-color: #ea292c;
}
.btn.btn-danger.active, .btn.btn-danger:active, .btn.btn-danger:not([disabled]):not(.disabled):active, .btn.btn-danger:not([disabled]):not(.disabled).active {
    background-color: #860d0f;
    box-shadow: 0 4px 9.6px 0.4px rgba(204, 20, 23, 0.5);
    color: #fff;
}
.btn.btn-danger.active:focus, .btn.btn-danger:active:focus, .btn.btn-danger:not([disabled]):not(.disabled):active:focus, .btn.btn-danger:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0 4px 9.6px 0.4px rgba(204, 20, 23, 0.5);
}
.btn.btn-danger[disabled], .btn.btn-danger[disabled]:hover {
    background-color: #860d0f;
    color: #410607;
    opacity: 0.5;
}
.btn.btn-warning {
  background-color: #f6a336;
  color: #fff;
  box-shadow: 0 4px 9.6px 0.4px rgba(246, 163, 54, 0.5);
  border: none;
}
.btn.btn-warning:hover {
    color: #fff;
    background-color: #f8b967;
}
.btn.btn-warning.active, .btn.btn-warning:active, .btn.btn-warning:not([disabled]):not(.disabled):active, .btn.btn-warning:not([disabled]):not(.disabled).active {
    background-color: #d67e0a;
    box-shadow: 0 4px 9.6px 0.4px rgba(246, 163, 54, 0.5);
    color: #fff;
}
.btn.btn-warning.active:focus, .btn.btn-warning:active:focus, .btn.btn-warning:not([disabled]):not(.disabled):active:focus, .btn.btn-warning:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0 4px 9.6px 0.4px rgba(246, 163, 54, 0.5);
}
.btn.btn-warning[disabled], .btn.btn-warning[disabled]:hover {
    background-color: #d67e0a;
    color: #8d5306;
    opacity: 0.5;
}
.btn.btn-info {
  background-color: #4ab2e3;
  color: #fff;
  box-shadow: 0 4px 9.6px 0.4px rgba(74, 178, 227, 0.5);
  border: none;
}
.btn.btn-info:hover {
    color: #fff;
    background-color: #76c5ea;
}
.btn.btn-info.active, .btn.btn-info:active, .btn.btn-info:not([disabled]):not(.disabled):active, .btn.btn-info:not([disabled]):not(.disabled).active {
    background-color: #1e8ec2;
    box-shadow: 0 4px 9.6px 0.4px rgba(74, 178, 227, 0.5);
    color: #fff;
}
.btn.btn-info.active:focus, .btn.btn-info:active:focus, .btn.btn-info:not([disabled]):not(.disabled):active:focus, .btn.btn-info:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0 4px 9.6px 0.4px rgba(74, 178, 227, 0.5);
}
.btn.btn-info[disabled], .btn.btn-info[disabled]:hover {
    background-color: #1e8ec2;
    color: #145d80;
    opacity: 0.5;
}
.btn.btn-success {
  background-color: #33cc00;
  color: #fff;
  box-shadow: 0 4px 9.6px 0.4px rgba(51, 204, 0, 0.5);
  border: none;
}
.btn.btn-success:hover {
    color: #fff;
    background-color: #40ff00;
}
.btn.btn-success.active, .btn.btn-success:active, .btn.btn-success:not([disabled]):not(.disabled):active, .btn.btn-success:not([disabled]):not(.disabled).active {
    background-color: #208000;
    box-shadow: 0 4px 9.6px 0.4px rgba(51, 204, 0, 0.5);
    color: #fff;
}
.btn.btn-success.active:focus, .btn.btn-success:active:focus, .btn.btn-success:not([disabled]):not(.disabled):active:focus, .btn.btn-success:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0 4px 9.6px 0.4px rgba(51, 204, 0, 0.5);
}
.btn.btn-success[disabled], .btn.btn-success[disabled]:hover {
    background-color: #208000;
    color: #0d3300;
    opacity: 0.5;
}
.btn.btn-dark {
  background-color: #161616;
  color: #fff;
  box-shadow: 0px 4px 70px -16px #161616;
  border: none;
}
.btn.btn-dark:hover {
    color: #fff;
    background-color: #303030;
}
.btn.btn-dark.active, .btn.btn-dark:active, .btn.btn-dark:not([disabled]):not(.disabled):active, .btn.btn-dark:not([disabled]):not(.disabled).active {
    background-color: black;
    box-shadow: 0px 4px 70px -16px #161616;
    color: #fff;
}
.btn.btn-dark.active:focus, .btn.btn-dark:active:focus, .btn.btn-dark:not([disabled]):not(.disabled):active:focus, .btn.btn-dark:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0px 4px 70px -16px #161616;
}
.btn.btn-dark[disabled], .btn.btn-dark[disabled]:hover {
    background-color: black;
    color: black;
    opacity: 0.5;
}
.btn.btn-pale {
  background-color: #c8c8c8;
  color: #fff;
  box-shadow: 0px 4px 70px -16px #c8c8c8;
  border: none;
}
.btn.btn-pale:hover {
    color: #fff;
    background-color: #e2e2e2;
}
.btn.btn-pale.active, .btn.btn-pale:active, .btn.btn-pale:not([disabled]):not(.disabled):active, .btn.btn-pale:not([disabled]):not(.disabled).active {
    background-color: #a2a2a2;
    box-shadow: 0px 4px 70px -16px #c8c8c8;
    color: #fff;
}
.btn.btn-pale.active:focus, .btn.btn-pale:active:focus, .btn.btn-pale:not([disabled]):not(.disabled):active:focus, .btn.btn-pale:not([disabled]):not(.disabled).active:focus {
      box-shadow: 0px 4px 70px -16px #c8c8c8;
}
.btn.btn-pale[disabled], .btn.btn-pale[disabled]:hover {
    background-color: #a2a2a2;
    color: #7c7c7c;
    opacity: 0.5;
}
.btn.btn-pale:hover {
    background-color: #d5d5d5;
}
.btn.btn-pale:hover:focus, .btn.btn-pale:hover:active, .btn.btn-pale:hover.active {
      background-color: #a2a2a2;
}
.btn.btn-with-icon {
  position: relative;
  padding: 1.1rem 2.8125rem;
}
.btn.btn-with-icon .btn-with-icon-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.875rem;
}
.btn.btn-with-icon i {
    position: absolute;
    left: 2.8125rem;
    top: 0.75rem;
    font-size: 2rem;
}
.btn.btn-with-icon.rounded-icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    padding: 0;
}
.btn.btn-with-icon.rounded-icon i {
      left: 1.23rem;
}
.btn.btn-with-icon.rounded-icon.btn-micro {
      height: 2.25rem;
      width: 2.25rem;
      flex: none;
}
.btn.btn-with-icon.rounded-icon.btn-micro i {
        font-size: 1.5rem;
        left: 0.46rem;
        top: 0.4rem;
}
.btn-group {
  box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
  border-radius: 1.875rem;
}
.btn-group.squared {
    border-radius: 5px;
}
.btn-group.squared.squared .btn {
      border-radius: 5px;
}
.btn-group.squared.squared .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
}
.btn-group.squared.squared .btn:not(:first-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
}
.btn-group.no-shadow {
    box-shadow: unset;
}
.btn-group.small-margin .btn {
    padding: .3rem .8rem;
}
.btn-group .btn {
    padding-left: 1.53rem;
    padding-right: 1.53rem;
    box-shadow: none;
}
.btn.dropdown-toggle {
  position: relative;
}
.btn.dropdown-toggle .arrow-down {
    position: absolute;
    font-size: 1rem;
    right: 1rem;
    top: calc(50% - 1rem/1.4);
}
.btn.dropdown-toggle::after {
    display: none;
}
.btn-group {
  color: #fff;
}
.dropdown-menu {
  border-radius: 0;
  padding: 10px 0;
  background: transparent;
  border: none;
}
.dropdown-menu .dropdown-menu-content {
    background-color: #171114;
    box-shadow: 0 4px 9.6px 0.4px rgba(46, 81, 123, 0.5);
}
.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font-size: 0.7875rem;
}
@media (min-width: 768px) {
.row > .col-8-custom {
    width: 12.5%;
}
}
@media (min-width: 576px) and (max-width: 767.98px) {
.row > .col-8-custom {
    width: 25%;
}
}
@media (max-width: 575.98px) {
.row > .col-8-custom {
    width: 100%;
}
}
.row {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}
[class^="col-"], [class*=" col-"] {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.error-icon {
  display: inline-block;
  color: #e34a4a;
}
.success-icon {
  display: inline-block;
  color: #33cc00;
}
.table ion-icon {
  font-size: 1.1rem;
  padding-left: 3px;
  padding-right: 3px;
}
.sort-icon {
  float: none !important;
  margin-left: .2rem;
  top: 1px;
  font-size: 1rem;
}
th.sortable:hover {
  color: #33cc00 !important;
  opacity: .6;
}
.badge {
  min-width: 5rem;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  padding: .5rem;
  font-size: .8rem;
  color: #fff;
  letter-spacing: .08rem;
}
.badge-violet {
  color: #fff;
  background-color: #db76df;
}
.badge-violet[href]:hover, .badge-violet[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #d04dd5;
}
.badge-dark-blue {
  color: #fff;
  background-color: #0045b6;
}
.badge-dark-blue[href]:hover, .badge-dark-blue[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #003283;
}
.badge-success {
  color: #fff;
  background-color: #33cc00;
}
.badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #269900;
}
.alert {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alert .badge {
    margin-right: 0.63rem;
}
.alert.with-close {
    padding-right: 3.125rem;
}
.alert .alert-close {
    position: absolute;
    right: 20px;
    font-size: 0.9rem;
    top: calc(50% - 0.9rem/2);
    cursor: pointer;
}
.alert-success {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 206, 145, 0.5);
}
.alert-success .alert-close {
    color: #33cc00;
}
.alert-warning {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 178, 79, 0.5);
}
.alert-warning .alert-close {
    color: #f6a336;
}
.alert-danger {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 79, 79, 0.5);
}
.alert-danger .alert-close {
    color: #cc1417;
}
.alert-info {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 142, 206, 0.5);
}
.alert-info .alert-close {
    color: #4ab2e3;
}
.alert-primary {
  color: #356a85;
  background-color: #e0f5ff;
  border-color: #d4f1ff;
}
.alert-primary hr {
    border-top-color: #bbe9ff;
}
.alert-primary .alert-link {
    color: #264d61;
}
.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-secondary hr {
    border-top-color: #f2f2f2;
}
.alert-secondary .alert-link {
    color: #6c6c6c;
}
.alert-success {
  color: #34495e;
  background-color: #e7ffb6;
  border-color: transparent;
}
.alert-success hr {
    border-top-color: rgba(0, 0, 0, 0);
}
.alert-success .alert-link {
    color: #22303d;
}
.alert-info {
  color: #34495e;
  background-color: #dcf1ff;
  border-color: transparent;
}
.alert-info hr {
    border-top-color: rgba(0, 0, 0, 0);
}
.alert-info .alert-link {
    color: #22303d;
}
.alert-warning {
  color: #34495e;
  background-color: #fff1c8;
  border-color: transparent;
}
.alert-warning hr {
    border-top-color: rgba(0, 0, 0, 0);
}
.alert-warning .alert-link {
    color: #22303d;
}
.alert-danger {
  color: #34495e;
  background-color: #ffcece;
  border-color: transparent;
}
.alert-danger hr {
    border-top-color: rgba(0, 0, 0, 0);
}
.alert-danger .alert-link {
    color: #22303d;
}
.alert-light {
  color: #7c7f7f;
  background-color: #fcfdfd;
  border-color: #fbfcfc;
}
.alert-light hr {
    border-top-color: #ecf1f1;
}
.alert-light .alert-link {
    color: #636565;
}
.alert-dark {
  color: #0b0b0b;
  background-color: #d0d0d0;
  border-color: #bebebe;
}
.alert-dark hr {
    border-top-color: #b1b1b1;
}
.alert-dark .alert-link {
    color: black;
}
.alert-pale {
  color: #686868;
  background-color: #f4f4f4;
  border-color: #f0f0f0;
}
.alert-pale hr {
    border-top-color: #e3e3e3;
}
.alert-pale .alert-link {
    color: #4f4f4f;
}
.form-control {
  border-width: 2px;
}
.button-container {
  text-align: center;
}
.btn-container {
  display: flex;
}
.btn-container .btn-group,
  .btn-container #subBtn {
    margin: auto;
}
fieldset {
  padding: 0;
  border: none;
}
.banner-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  color: #33cc00;
  border-bottom: #34495e solid 2px;
  margin-bottom: 10px;
  width: 100%;
}
.form-inline > .form-group,
.form-inline > .btn {
  display: inline-block;
  margin-bottom: 0;
}
input.vuestic-date-picker {
  border: none;
  padding: 0.1125rem 0.225rem 0.05625rem;
  border-bottom: 0.05625rem solid #999;
  margin-left: 0.18rem;
  margin-right: 1.08rem;
  font-size: .9rem;
}
.abc-checkbox,
.abc-radio {
  display: flex !important;
  justify-content: flex-start;
  margin-left: 25px;
}
.form-help {
  margin-top: 0.1125rem;
  margin-left: 0.1125rem;
  color: #b3b3b3;
  font-size: 0.765rem;
}
.form-group .form-help {
    position: absolute;
    width: 100%;
}
.checkbox .form-help {
    position: relative;
    margin-bottom: 0.9rem;
}
.valid .legend.legend,
.valid.form-group .control-label.control-label {
  color: #66ccff;
}
.valid .valid-icon {
  display: inline-block;
  color: #66ccff;
}
.invalid {
  color: #919191;
}
.has-error .legend.legend,
.has-error.form-group .control-label.control-label {
  color: #cc1417;
}
.has-error .error-icon {
  display: inline-block;
  color: #cc1417;
}
.has-error.form-group .icon-right {
  color: #cc1417;
}
.has-error.form-group .form-help,
.has-error.form-group .helper, .has-error.checkbox .form-help,
.has-error.checkbox .helper {
  color: #cc1417;
}
.has-error .bar::before {
  background: #cc1417;
  left: 0;
  width: 100%;
}

/* DatePicker */
.bootstrap-datetimepicker-widget .table-condensed .glyphicon {
  color: #930000 !important;
}
.bootstrap-datetimepicker-widget table thead th {
  color: black !important;
  text-transform: unset !important;
  border: unset;
}
.bootstrap-datetimepicker-widget .timepicker .btn {
  box-shadow: unset;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  background: white !important;
  border: #adb3b9 solid 1px;
  box-shadow: 0 4px 70px -18px #606066;
}
.bootstrap-datetimepicker-widget .glyphicon a {
  color: black;
}

/* Multiselect */
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #33cc00 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  -webkit-animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
          animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  -webkit-animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
          animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity .4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 14px;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  opacity: .6;
}
.multiselect--active {
  z-index: 1;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 1px 0 0 5px;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 6px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #33cc00;
  margin-bottom: 8px;
  white-space: nowrap;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all .2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform .2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
}
.multiselect__option--highlight {
  background: #33cc00;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #33cc00;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  cursor: text;
  pointer-events: none;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all .15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 10px;
  display: inline-block;
}
[dir=rtl] .multiselect {
  text-align: right;
}
[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir=rtl] .multiselect__content {
  text-align: right;
}
[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@-webkit-keyframes a {
0% {
    transform: rotate(0);
}
to {
    transform: rotate(2turn);
}
}
@keyframes a {
0% {
    transform: rotate(0);
}
to {
    transform: rotate(2turn);
}
}

/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
:root {
  --vs-light: 163, 224, 255 !important;
  --vs-primary: 46, 81, 123 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
    font-weight: bold;
    color: #2c3e50;
}
#nav a.router-link-exact-active {
      color: #42b983;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.widget {
  background: white;
  box-shadow: 0px 4px 70px -18px #707070;
  margin-bottom: 1.25rem;
  padding: 0;
  border-radius: 5px;
  width: 100%;
}
.widget .widget-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-weight: bold;
    font-size: 1.25rem;
    width: 100%;
}
.widget .widget-header.widget-header-centered {
      text-align: center;
}
.widget .widget-body {
    padding: 1.5rem;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.antenna-fiche[data-v-c3a68066] {
  flex: 2;
}
.widget[data-v-c3a68066] {
  height: 100%;
}
.infos[data-v-c3a68066] {
  flex: 2;
}
.info-wrapper[data-v-c3a68066] {
  max-width: 400px;
}
.info-wrapper .address[data-v-c3a68066] {
    justify-content: space-between;
}
.horaires[data-v-c3a68066] {
  flex: 1;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.infos {
  flex: 2;
}
.widget {
  height: 100%;
  width: 100%;
}
.info-wrapper {
  max-width: 250px;
}
.info-wrapper .address {
    justify-content: space-between;
}
.horaires {
  flex: 1;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.antenna-fiche[data-v-2c6d2134] {
  flex: 2;
}
.siege-fiche[data-v-2c6d2134] {
  flex: 1;
}
.alert-dashboard[data-v-2c6d2134] {
  flex: 1;
}
.activity-dashboard[data-v-2c6d2134] {
  flex: 1;
}
.data-quality-dashboard[data-v-2c6d2134] {
  flex: 1;
}
.housing-dashboard[data-v-2c6d2134] {
  flex: 1;
}
.domiciliation-dashboard[data-v-2c6d2134] {
  flex: 1;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.login-widget {
  background-color: #d6f1ff;
  padding: 2rem;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader[data-v-7d910697] {
  font-size: 1.2rem;
}
.md-progress-bar[data-v-7d910697] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.md-checkbox[data-v-7d910697] {
  margin: 22px 16px 16px 0px !important;
}
.period[data-v-7d910697] {
  margin-top: 16px;
  margin-bottom: 16px;
}
.no-underline.md-field[data-v-7d910697]:before {
  visibility: hidden !important;
}
.no-underline.md-field[data-v-7d910697]:after {
  visibility: hidden !important;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.day {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid white;
}
.day:hover {
    border: 1px solid black;
}
.room-square-total {
  border-radius: 20%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}
.room-square-total.square-green {
    background-color: #c6f1b8;
}
.room-square-total.square-red {
    background-color: #f1bdbe;
}
.room-circle-antenna {
  border-radius: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
}
.room-circle-antenna.circle-green {
    background-color: #c6f1b8;
}
.room-circle-antenna.circle-orange {
    background-color: #fce5c7;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader {
  font-size: 1.2rem;
}
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.period {
  margin-top: 16px;
  margin-bottom: 16px;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.page-link {
  cursor: pointer;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader {
  font-size: 1.2rem;
}
.no-underline.md-field:before {
  visibility: hidden !important;
}
.no-underline.md-field:after {
  visibility: hidden !important;
}
.period {
  margin-top: 16px;
  margin-bottom: 16px;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.one-detention[data-v-fdb022a0] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.one-detention[data-v-fdb022a0]:first-child {
    padding-top: 1.5rem;
}
.meetings[data-v-fdb022a0] {
  min-width: 150px;
}
.meetings ion-icon[data-v-fdb022a0] {
    font-size: 1.2rem;
    cursor: pointer;
    color: #66ccff;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.alert[data-v-6d85edbb] {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alert .badge[data-v-6d85edbb] {
    margin-right: 0.63rem;
}
.alert.with-close[data-v-6d85edbb] {
    padding-right: 3.125rem;
}
.alert .alert-close[data-v-6d85edbb] {
    position: absolute;
    right: 20px;
    font-size: 0.9rem;
    top: calc(50% - 0.9rem/ 2);
    cursor: pointer;
}
.alert-success[data-v-6d85edbb] {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 206, 145, 0.5);
}
.alert-success .alert-close[data-v-6d85edbb] {
    color: #33cc00;
}
.alert-warning[data-v-6d85edbb] {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 178, 79, 0.5);
}
.alert-warning .alert-close[data-v-6d85edbb] {
    color: #f6a336;
}
.alert-danger[data-v-6d85edbb] {
  box-shadow: 0px 4px 9.6px 0.4px rgba(206, 79, 79, 0.5);
}
.alert-danger .alert-close[data-v-6d85edbb] {
    color: #cc1417;
}
.alert-info[data-v-6d85edbb] {
  box-shadow: 0px 4px 9.6px 0.4px rgba(79, 142, 206, 0.5);
}
.alert-info .alert-close[data-v-6d85edbb] {
    color: #4ab2e3;
}
.fade-enter-active[data-v-6d85edbb],
.fade-leave-active[data-v-6d85edbb] {
  transition: opacity 0.5s;
}
.fade-enter[data-v-6d85edbb], .fade-leave-to[data-v-6d85edbb] {
  opacity: 0;
}
@media (max-width: 767.98px) {
.alert[data-v-6d85edbb] {
    flex-direction: column;
}
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.tabs-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 !important;
}
.tabs-wrapper .tabs-content {
    background-color: white;
    display: flex;
    align-items: center;
    margin: 5px 0 7px 0;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    min-height: 48px;
    border-radius: 5px;
}
.tabs-wrapper .tabs-content .content:hover {
      cursor: pointer;
      background-color: #66ccff;
}
.tabs-wrapper .tabs-content .content:hover .tab {
        background-color: #66ccff;
        color: #fff;
        border-right: 2px solid #ffae0d;
}
.tabs-wrapper .tabs-content .content:hover .tab i {
          color: #fff;
}
.tabs-wrapper .tabs-content .content:hover.selected {
        background-color: #2e517b;
}
.tabs-wrapper .tabs-content .content:hover.selected .tab {
          background-color: #2e517b;
}
.tabs-wrapper .tabs-content .content .tab {
      min-width: 100px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      border-right: 1px solid #3f3f3f;
      margin: 10px 0;
}
.tabs-wrapper .tabs-content .content .tab .fa {
        margin: 0 8px;
}
.tabs-wrapper .tabs-content .selected {
      cursor: pointer;
      background-color: #2e517b;
}
.tabs-wrapper .tabs-content .selected .tab {
        background-color: #2e517b;
        color: #fff;
        border-right: 2px solid #ffae0d;
}
.tabs-wrapper .tabs-content .selected .tab i {
          color: #fff;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.table-info {
  font-weight: bold;
}
.table-info td {
    font-weight: bold;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader[data-v-5cb6bd4e] {
  font-size: 1.2rem;
}
.horaire .md-input[data-v-5cb6bd4e] {
  max-width: 120px;
}

@charset "UTF-8";
/**
 * Définir ici toutes les variables utilisées dans la vue.
 * Toutes les variables devraient se terminer par "!default"
 * pour que les customers puissent les remplacés
 */
.md-subheader[data-v-0be875b0] {
  font-size: 1.2rem;
}
.horaire .md-input[data-v-0be875b0] {
  max-width: 120px;
}


.action-cell {
  cursor: pointer;
}
.page-link {
  cursor: pointer;
}


/*# sourceMappingURL=app.f2e671f4.css.map*/